import React, { useState, useEffect } from 'react'
import styles from './Translate.module.css'
import IconButton from '../../ui-kit/IconButton'
import TextareaAutosize from 'react-textarea-autosize'
import Button from '../../ui-kit/Button'
import * as Tooltip from '@radix-ui/react-tooltip'
import {
  CloseIcon,
  OkIcon,
  // ReloadIcon
} from '../../assets'
import { app } from '../../services/firebase'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { languages } from '../../utilities/languages'
import { useEditorContext } from '../../contexts/EditorContext'
import { useModulesContext } from '../../contexts/ModulesContext'

const functions = getFunctions(app)

interface TranslateTextProps {
  initialText?: string
  show?: boolean
}

const TranslateText: React.FC<TranslateTextProps> = ({
  initialText = '',
  show = false,
}) => {
  const [firstText, setFirstText] = useState(initialText)
  const [secondText, setSecondText] = useState('')
  const [thirdText, setThirdText] = useState('')
  const { updateText } = useEditorContext()

  const { isTranslateTextOpen, closeTranslateText } = useModulesContext()

  useEffect(() => {
    setFirstText(initialText)
  }, [initialText])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (firstText && firstText.length > 0) {
        handleTranslate()
      }
    }, 500) // 500 миллисекунд задержки

    return () => clearTimeout(timer) // очистка таймера
  }, [firstText])

  const { sourceLanguage, targetLanguage } = useSelector(
    (state: RootState) => state.language
  )

  show = isTranslateTextOpen

  const translateTextClassName = show
    ? styles.options
    : `${styles.options} ${styles.hidden}`

  const maxRows = 11

  interface TranslationResponse {
    success: boolean
    translatedText?: string
    debugInfo?: any
    error?: string
  }

  const handleTranslate = async () => {
    // const translateTextFunction = httpsCallable(functions, 'translateText')
    const googleTranslateWord = httpsCallable(functions, 'googleTranslateWord')
    try {
      const result = await googleTranslateWord({
        // sourceLanguage: languages[sourceLanguage].deeplSource,
        targetLanguageCode: languages[targetLanguage].googleCode,
        text: firstText,
      })
      const response = result.data as TranslationResponse // Приведение к типу
      setSecondText(response.translatedText || '')

      // Вызов функции для обратного перевода
      if (response.translatedText) {
        handleReverseTranslate(response.translatedText)
      }
    } catch (error) {
      const e = error as { debugInfo?: any } // Приведение к типу
      console.error('Ошибка перевода:', e)
    }
  }

  const handleReverseTranslate = async (textToReverseTranslate: string) => {
    // const translateTextFunction = httpsCallable(functions, 'translateText')
    const googleTranslateWord = httpsCallable(functions, 'googleTranslateWord')
    try {
      const result = await googleTranslateWord({
        // sourceLanguage: languages[targetLanguage].googleCode,
        targetLanguageCode: languages[sourceLanguage].googleCode,
        text: textToReverseTranslate,
      })
      const response = result.data as TranslationResponse // Приведение к типу
      setThirdText(response.translatedText || '')
    } catch (error) {
      const e = error as { debugInfo?: any } // Приведение к типу
      console.error('Ошибка перевода:', e)
    }
  }

  const handleApplyTranslation = () => {
    // Обновляем текст в редакторе. Второй аргумент - это сохраненное выделение, если у вас таковое есть.
    console.log('call updateText', secondText)
    updateText(secondText)

    // Закрываем окно
    closeTranslateText()
  }

  return (
    <div className={translateTextClassName}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>{languages[sourceLanguage].name}</div>
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <IconButton size="s" onClick={closeTranslateText}>
                  <CloseIcon />
                </IconButton>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content
                  className={styles.TooltipContent}
                  sideOffset={5}
                >
                  Close
                  <Tooltip.Arrow className={styles.TooltipArrow} />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
        <TextareaAutosize
          // className={styles.textArea}
          className={`${styles.textAria} ${styles.firstText}`}
          value={firstText}
          onChange={(e) => setFirstText(e.target.value)}
          maxRows={maxRows}
          placeholder="Enter your text"
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>{languages[targetLanguage].name}</div>
          <div className={styles.buttonWrapper}>
            {/* <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <IconButton size="s" onClick={handleTranslate}>
                    <ReloadIcon />
                  </IconButton>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    className={styles.TooltipContent}
                    sideOffset={5}
                  >
                    Translate
                    <Tooltip.Arrow className={styles.TooltipArrow} />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider> */}

            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <IconButton size="s" onClick={handleApplyTranslation}>
                    <OkIcon />
                  </IconButton>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    className={styles.TooltipContent}
                    sideOffset={5}
                  >
                    Apply
                    <Tooltip.Arrow className={styles.TooltipArrow} />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
        </div>
        <TextareaAutosize
          className={`${styles.textAria} ${styles.secondText}`}
          value={secondText}
          // readOnly
          maxRows={maxRows}
          placeholder="Loading..."
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>{languages[sourceLanguage].name}</div>
          <div className={styles.status}>100%</div>
        </div>
        <TextareaAutosize
          className={styles.textAria}
          value={thirdText}
          readOnly
          maxRows={maxRows}
          placeholder="Loading..."
        />
      </div>
      <div className={styles.applyButton}>
        <Button
          size="s"
          kind="secondary"
          isFullWidth
          onClick={handleApplyTranslation}
        >
          Apply translation
        </Button>
      </div>
    </div>
  )
}

export default TranslateText
