import React, { useEffect, useState } from 'react'
import styles from './Loading.module.css'
import Spinner from './Spinner'

function Loading() {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false)
    }, 500)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div
      className={styles.wrapper}
      style={{ display: isVisible ? 'flex' : 'none' }}
    >
      <Spinner />
      Loading...
    </div>
  )
}

export default Loading
