import { FC, ReactEventHandler, useState } from 'react'
import { connect, DispatchProp, useSelector } from 'react-redux'
import { appDispatch, createUserAndSignIn, selectError } from '../store'
import ReactGA from 'react-ga4'

import styles from './SignUpbyMail.module.css'
import Button from '../ui-kit/Button'
import Form from '../ui-kit/Form'
import Input from '../ui-kit/Input'
import Divider from '../ui-kit/Divider'

const SignUpbyMail: FC<DispatchProp> = ({ dispatch }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)

  const error = useSelector(selectError)

  const handleSubmit: ReactEventHandler = (event) => {
    event.preventDefault()

    // Предположим, createUserAndSignIn это асинхронное действие, которое возвращает Promise
    appDispatch(
      createUserAndSignIn({
        name,
        email,
        password,
      })
    )
      .then((user) => {
        if (user) {
          // Регистрация успешна, отправьте событие в Google Analytics
          ReactGA.event({
            category: 'Registration',
            action: 'User Signed Up',
            label: 'Sign Up by Email',
          })
        }
      })
      .catch((error) => {
        console.error('Registration failed', error)
      })
  }

  return (
    <div className={styles.column}>
      <div className={styles.wrapper}>
        <Divider direction="H" />
        <div className={styles.text}>Or Sign Up by Email</div>
        <Divider direction="H" />
      </div>
      <Form
        checkForValidityOn={[name, email, password]}
        errorMessage={error}
        onSubmit={handleSubmit}
        validityCallback={setIsFormValid}
      >
        <Input
          autoComplete="given-name"
          isRequired
          label="Name"
          onChange={setName}
          placeholder="Type your name"
          value={name}
        />
        <Input
          autoComplete="email"
          isRequired
          label="Email"
          onChange={setEmail}
          placeholder="username@example.com"
          type="email"
          value={email}
        />
        <Input
          autoComplete="new-password"
          isRequired
          label="Password"
          minLength={6}
          onChange={setPassword}
          type="password"
          value={password}
        />
        <Button kind={isFormValid ? 'primary' : 'disabled'}>Sign up</Button>
      </Form>
    </div>
  )
}

export default connect()(SignUpbyMail)
