import LanguageDetect from 'languagedetect'
import { detect as tinyldDetect } from 'tinyld'
import { languages } from './languages'
import { francAll } from 'franc-min'

const lngDetector = new LanguageDetect()

// Устанавливаем формат языка на iso2 (двухбуквенный формат)
lngDetector.setLanguageType('iso2')

export const detectLanguage = (
  text: string,
  sourceLanguage: string
): string | null => {
  // Проверяем, является ли текст пустой строкой или undefined
  if (!text) {
    console.error('Invalid argument: text is required')
    throw new Error('Invalid argument: text is required')
  }

  const result = lngDetector.detect(text, 5)

  // Обработка случая, когда result пустой или не может быть прочитан
  if (
    !result ||
    result.length === 0 ||
    !Array.isArray(result[0]) ||
    (result[0] as any)?.length === 0
  ) {
    return null
  }

  for (let i = 0; i < Math.min(result.length, 5); i++) {
    if (
      result[i] &&
      result[i][0] &&
      result[i][0] === languages[sourceLanguage].detectLang
    ) {
      return result[i][0]
    }
  }

  return result[0] && result[0][0] ? result[0][0] : null
}

interface Detect {
  data: string
}

export const detectTinyld = async (data: Detect) => {
  const text = data.data

  if (!text) {
    console.log(
      'invalid-argument',
      'Must be called with one argument "text" to analyze.'
    )
    throw new Error('Invalid argument: text is required')
  }

  try {
    const language = tinyldDetect(text)
    return { language }
  } catch (error) {
    console.error('Error detecting language:', error)
    throw new Error('Failed to detect language using tinyld')
  }
}

export const detectFranc = (
  text: string,
  sourceLanguage: string
): string | null => {
  if (!text) {
    console.error('Invalid argument: text is required')
    throw new Error('Invalid argument: text is required')
  }

  const detectedLanguage = francAll(text, { minLength: 1 })

  if (
    !detectedLanguage ||
    detectedLanguage.length === 0 ||
    !Array.isArray(detectedLanguage) ||
    detectedLanguage[0][0] === null
  ) {
    // console.error('Failed to detect language using franc-min');
    return null
  }

  if (
    detectedLanguage.length > 0 &&
    detectedLanguage[0][0] === languages[sourceLanguage].detectFranc
  ) {
    return detectedLanguage[0][0]
  }

  if (
    detectedLanguage.length > 0 &&
    detectedLanguage[1][0] === languages[sourceLanguage].detectFranc
  ) {
    return detectedLanguage[1][0]
  }

  if (
    detectedLanguage.length > 0 &&
    detectedLanguage[2][0] === languages[sourceLanguage].detectFranc
  ) {
    return detectedLanguage[2][0]
  }

  if (
    detectedLanguage.length > 0 &&
    detectedLanguage[3][0] === languages[sourceLanguage].detectFranc
  ) {
    return detectedLanguage[3][0]
  }

  if (
    detectedLanguage.length > 0 &&
    detectedLanguage[4][0] === languages[sourceLanguage].detectFranc
  ) {
    return detectedLanguage[4][0]
  }

  return null
}
