import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { doc, setDoc } from 'firebase/firestore'
import { RootState } from './' // Убедитесь, что путь к файлу store правильный
import { firestore } from '../services/firebase'
import { selectCurrentNoteID } from './notesStore' // Убедитесь, что путь к файлу notesSlice правильный

interface LanguageState {
  sourceLanguage: string
  targetLanguage: string
}

const initialState: LanguageState = {
  sourceLanguage: 'Ukrainian',
  targetLanguage: 'English',
}

export const updateSourceLanguage = createAsyncThunk<
  void,
  { sourceLanguage: string },
  { state: RootState }
>('language/updateSourceLanguage', async ({ sourceLanguage }, thunkAPI) => {
  const currentNoteID = selectCurrentNoteID(thunkAPI.getState())
  if (sourceLanguage && currentNoteID) {
    const noteRef = doc(firestore, 'notes', currentNoteID)
    await setDoc(noteRef, { sourceLanguage }, { merge: true })
  }
})

export const updateTargetLanguage = createAsyncThunk<
  void,
  { targetLanguage: string },
  { state: RootState }
>('language/updateTargetLanguage', async ({ targetLanguage }, thunkAPI) => {
  const currentNoteID = selectCurrentNoteID(thunkAPI.getState())
  if (targetLanguage && currentNoteID) {
    const noteRef = doc(firestore, 'notes', currentNoteID)
    await setDoc(noteRef, { targetLanguage }, { merge: true })
  }
})

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateSourceLanguage.fulfilled, (state, action) => {
      const { sourceLanguage } = action.meta.arg
      state.sourceLanguage = sourceLanguage
    })
    builder.addCase(updateTargetLanguage.fulfilled, (state, action) => {
      const { targetLanguage } = action.meta.arg
      state.targetLanguage = targetLanguage
    })
  },
})

export const selectSourceLanguage = (state: RootState) =>
  state.language.sourceLanguage
export const selectTargetLanguage = (state: RootState) =>
  state.language.targetLanguage

export default languageSlice.reducer
