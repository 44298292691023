import React, { forwardRef } from 'react'
import styles from './Billing.module.css'
import Divider from '../../ui-kit/Divider'
import Button from '../../ui-kit/Button'
import { OkIcon } from '../../assets'

interface BillingProps {
  show: boolean
}

const Billing = forwardRef<HTMLButtonElement, BillingProps>((props, ref) => {
  const { show } = props

  return (
    <div className={styles.conteiner}>
      <div
        className={`${styles.wrapper} ${show ? styles.visible : styles.hidden}`}
      >
        <div className={styles.blockFirst}>
          <div className={styles.blockTitle}>Current plan: Spark</div>
          <div className={styles.textSecond}>Words left: 3,420</div>
          {/* <div className={styles.blockContent}>
          <div className={styles.contentleft}>Translation</div>
          <div className={styles.contentright}>562</div>
        </div> */}
        </div>
        <Divider direction="H" />
        <div className={styles.block}>
          <div className={styles.blockTitle}>Upgrade to Diglot PRO</div>
          {/* <div className={styles.subTitle}>Your current plan:</div> */}
          <div className={styles.blockContentFeature}>
            <div className={styles.featureRow}>
              <OkIcon className={styles.iconBulet} />
              {/* 🧠{' '} */}
              <div className={styles.feature}>
                <span className={styles.featureTitle}>
                  AI Writing Assistance
                </span>{' '}
                <span>Sharpen your prose with smart guidance.</span>
              </div>
            </div>

            <div className={styles.featureRow}>
              <OkIcon className={styles.iconBulet} />
              {/* 🎓{' '} */}
              <div className={styles.feature}>
                <span className={styles.featureTitle}>
                  Built-in & Double Translation
                </span>{' '}
                <span>Write boldly; we'll catch the errors.</span>
              </div>
            </div>

            <div className={styles.featureRow}>
              <OkIcon className={styles.iconBulet} />

              {/* ✍️{' '} */}
              <div className={styles.feature}>
                <span className={styles.featureTitle}>
                  Grammar & Spelling Checker
                </span>{' '}
                <span>Spotless writing for a professional look.</span>
              </div>
            </div>

            <div className={styles.featureRow}>
              <OkIcon className={styles.iconBulet} />
              {/* 🚀{' '} */}
              <div className={styles.feature}>
                <span className={styles.featureTitle}>
                  Parahpasing & Synonyms Tool
                </span>{' '}
                <span>Unlimited creativity, endless expression.</span>
              </div>
            </div>

            <div className={styles.featureRow}>
              <OkIcon className={styles.iconBulet} />
              {/* 🌍{' '} */}
              <div className={styles.feature}>
                <span className={styles.featureTitle}>
                  100k Words Translation
                </span>{' '}
                <span>Overcome language barriers</span>
              </div>
            </div>
          </div>

          <Button size="s" kind="primary" ref={ref} isFullWidth>
            Upgrade to Pro
          </Button>
        </div>
      </div>
    </div>
  )
})

export default Billing
