import React, { useState, useEffect } from 'react'
import styles from './BetaApply.module.css'
import User from '../sidebar/components/User'
import Divider from '../ui-kit/Divider'
import Input from '../ui-kit/Input'
import Button from '../ui-kit/Button'
import AppLayout from './AppLayout'
import ReactGA from 'react-ga4'

function BetaApply() {
  const [accessCode, setAccessCode] = useState('')
  const [isAccessGranted, setIsAccessGranted] = useState(
    localStorage.getItem('isAccessGranted') === 'true'
  )
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    const accessGranted = localStorage.getItem('isAccessGranted') === 'true'
    setIsAccessGranted(accessGranted)

    localStorage.setItem('isAccessGranted', accessGranted ? 'true' : 'false')
  }, [isAccessGranted])

  const handleAccessCodeChange = (value: string) => {
    setAccessCode(value)
    setShowError(false)
  }

  const handleAccessRequest = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (accessCode === 'proxima') {
      setIsAccessGranted(true)
      localStorage.setItem('isAccessGranted', 'true') // Сохраняем значение в локальное хранилище
    } else {
      setShowError(true)
    }
  }

  const handleApplyForBetaClick = () => {
    // Триггер события GA при клике на кнопку
    ReactGA.event({
      category: 'Beta Apply',
      action: 'Beta Apply Click',
      label: 'Apply for Beta Button',
    })
  }

  if (isAccessGranted) {
    return <AppLayout />
  }

  return (
    <>
      <div className={styles.header}>
        <div className={styles.user}>
          <User />
        </div>
        <div className={styles.headetText2}>Diglot.ai</div>
        <div className={styles.headetText}>Private Beta v. 0.0.9</div>
      </div>
      <Divider direction="H" />
      <div className={styles.section}>
        <div className={styles.container}>
          <div className={styles.notif}>
            <h1>Apply for Beta and Get a Free Pro Plan!</h1>
            <p className={styles.about}>
              Join now and be one of the first to test our newest features
              before they're released to everyone.
            </p>
            <p>By joining our beta testing, you can:</p>
            <ul className={styles.ul2}>
              <li>
                Get 3 months of the Pro plan for free when we officially launch.
              </li>
              <li>Try our cool new features before anyone else.</li>
              <li>
                Help make the product better by giving us your thoughts and
                ideas.
              </li>
            </ul>
            <p>
              To sign up for our private beta testing and get free access to the
              Pro plan, just press the button below.
            </p>
            <a
              href="https://tripetto.app/run/2O3JD54KXW"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
              onClick={handleApplyForBetaClick}
            >
              Apply for Beta
            </a>
          </div>

          <div className={styles.divider}>
            <Divider direction="H" />
          </div>
          <form className={styles.form}>
            <h1>Access to Diglot.ai Private Beta</h1>
            <p className={styles.about}>
              Beta access code will be sent to your email once we add you to
              beta-testers list.
            </p>
            <div className={styles.access}>
              <Input
                label="Beta access code"
                autofocus
                minLength={6}
                value={accessCode}
                onChange={(event) => handleAccessCodeChange(event)}
              />
              <div className={styles.buttonA}>
                <Button onClick={handleAccessRequest}>Get access</Button>
              </div>
            </div>
            {showError && (
              <div className={styles.alert}>Wrong Beta Access Code</div>
            )}
          </form>
        </div>
      </div>
    </>
  )
}

export default BetaApply
