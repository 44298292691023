import styles from './Teaser.module.css'

import { MouseEventHandler } from 'react'
import { FileTextIcon } from '../../assets'
// import Time from './TimeAgo'

const Teaser = (props: {
  date: Date | string
  isActive?: boolean
  onClick: MouseEventHandler
  title?: string
}) => {
  const { isActive, onClick, title } = props
  return (
    <button
      onClick={onClick}
      className={`${styles.wrapper} ${isActive ? styles.wrapperIsActive : ''}`}
    >
      <FileTextIcon className={styles.icon} />

      <h4
        dangerouslySetInnerHTML={{ __html: title || 'Untitled Note' }}
        className={styles.title}
      />
      {/* <div className="text--s text--light">
        <Time date={date} />
      </div> */}
    </button>
  )
}

export default Teaser
