import React from 'react'
import styles from './PlanTeaser.module.css'
import Upgrade from '../../upgrade/Upgrade'

function PlanTeaser() {
  return (
    <>
      <Upgrade>
        <span>
          <button className={styles.wrapper}>
            <div className={styles.currentPlan}>
              <span className={styles.planName}>Spark plan</span>
              <span className={styles.words}>• Free / 5K words</span>
            </div>
            <div className={styles.getMore}>GET MORE WORDS</div>
          </button>
        </span>
      </Upgrade>
    </>
  )
}

export default PlanTeaser
