import { createSelector } from 'reselect'
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { auth, firestore } from '../services/firebase'
import { RootState, NoteLike } from '.'
import {
  compareDateRecency,
  noteConverter,
  sortNotes,
} from '../utilities/helpers'

const ShortUniqueId = require('short-unique-id')

const uid = new ShortUniqueId()

type InitialStateType = {
  all: NoteLike[]
  currentID: string | null
  sourceLanguage: string
  targetLanguage: string
}

const initialState: InitialStateType = {
  all: [],
  currentID: null,
  sourceLanguage: 'UK',
  targetLanguage: 'en-US',
}

const replaceNote = (note: NoteLike, notes: NoteLike[]): NoteLike[] => {
  const newNote: NoteLike = { ...note }

  return notes.map((note) => {
    if (note.id === newNote.id) {
      return newNote
    } else {
      return note
    }
  })
}

export const deleteNoteAndSetCurrent = createAsyncThunk(
  'notes/destroyNote',
  async (noteID: string, thunkAPI) => {
    try {
      await deleteDoc(doc(firestore, `notes/${noteID}`))
      return noteID
    } catch (error) {
      console.error(error)
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const fetchNotes = createAsyncThunk(
  'notes/fetch',
  async (currentUserId: string, thunkAPI) => {
    if (!currentUserId) {
      return thunkAPI.rejectWithValue(new Error('User logged out, could not fetch notes.'))
    }

    try {
      const notes: NoteLike[] = []
      const notesQuery = query(
        collection(firestore, 'notes').withConverter(noteConverter),
        where('noteUserID', '==', currentUserId)
      )
      const notesSnapshot = await getDocs(notesQuery)
      notesSnapshot.forEach((noteSnapshot) => {
        notes.push(noteSnapshot.data())
      })
      return notes
    } catch (error) {
      return thunkAPI.rejectWithValue(new Error('Could not fetch notes'))
    }
  }
)

const generateEmptyNote = (userID: string, date: Date | string): NoteLike => {
  const newDate = typeof date === 'string' ? date : date.toISOString()
  return {
    lastModifiedDate: newDate,
    createdDate: newDate,
    id: uid.rnd(), // Использование метода rnd() для генерации уникального идентификатора
    title: '',
    body: '',
    noteUserID: userID,
    sourceLanguage: 'UK',
    targetLanguage: 'en-US',
  }
}

export const postNote = createAsyncThunk(
  'notes/postNote',
  async (note: NoteLike, thunkAPI) => {
    const docRef = doc(firestore, `notes/${note.id}`).withConverter(noteConverter)

    try {
      await setDoc(docRef, note)
      return note
    } catch (error) {
      console.error(error)
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const selectCurrentNote = (state: RootState) => {
  if (state.notes.currentID) return selectNote(state.notes.currentID, state)
}

export const selectNote = (noteID: string, state: RootState) => {
  return state.notes.all.find((note) => note.id === noteID)
}

export const selectCurrentNoteID = (state: RootState) => {
  return state.notes.currentID
}

export const selectNotes = createSelector(
  (state: RootState) => state.notes.all,
  (allNotes) => {
    return sortNotes([...allNotes])
  }
)

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    createNoteAndSetCurrent: (
      state,
      action: PayloadAction<{ userID: string; date: string }>
    ) => {
      const newNote = generateEmptyNote(
        action.payload.userID,
        action.payload.date
      )

      state.currentID = newNote.id
      state.all.push(newNote)

      return state
    },
    reset: () => {
      return initialState
    },
    setCurrentNote: (state, action: PayloadAction<{ noteID: string }>) => {
      return { ...state, currentID: action.payload.noteID }
    },
    /* Update Note in store only */
    updateNote: (state, action: PayloadAction<NoteLike>) => {
      const newNote: NoteLike = { ...action.payload }
      return { ...state, all: replaceNote(newNote, state.all) }
    },
    setSourceLanguage: (state, action: PayloadAction<string>) => {
      state.sourceLanguage = action.payload
    },
    setTargetLanguage: (state, action: PayloadAction<string>) => {
      state.targetLanguage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotes.fulfilled, (state, action) => {
      const sortedNotes = sortNotes([...action.payload!])

      if (state.all.length === 0 && sortedNotes.length > 0) {
        state.all = sortedNotes
        state.currentID = sortedNotes[0].id
      } else if (sortedNotes.length > 0) {
        const currentNotes = [...state.all]

        const newNotes = action.payload.filter((newNote) => {
          const currentNoteIndex = currentNotes.findIndex((currentNote) => {
            return currentNote.id === newNote.id
          })

          if (
            currentNoteIndex !== -1 &&
            compareDateRecency(
              currentNotes[currentNoteIndex].lastModifiedDate,
              newNote.lastModifiedDate
            ) === 1
          ) {
            return false
          } else if (currentNoteIndex !== -1) {
            currentNotes.splice(currentNoteIndex, 1)
          }

          return true
        })

        state.all = sortNotes([...currentNotes, ...newNotes])
      } else if (auth.currentUser) {
        const emptyNote = generateEmptyNote(auth.currentUser.uid, new Date())
        state.all = [emptyNote]
        state.currentID = emptyNote.id
      }
    })

    builder.addCase(deleteNoteAndSetCurrent.fulfilled, (state, action) => {
      state.all = sortNotes([...state.all])

      const noteIndex = state.all.findIndex(
        (note) => note.id === action.payload
      )

      if (noteIndex !== -1) {
        state.all.splice(noteIndex, 1)
      }

      if (state.all.length === 0) {
        if (auth.currentUser) {
          const emptyNote = generateEmptyNote(auth.currentUser.uid, new Date())
          state.all.push(emptyNote)
        }
      }

      state.currentID = state.all.length > 0 ? state.all[0].id : null
    })
  },
})

export const {
  createNoteAndSetCurrent,
  reset,
  setCurrentNote,
  updateNote,
  setSourceLanguage,
  setTargetLanguage,
} = notesSlice.actions

export default notesSlice.reducer
