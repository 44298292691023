import styles from './Cowriter.module.css'
import IconButton from '../../ui-kit/IconButton'
import { CloseIcon, ReloadIcon } from '../../assets'
import TextareaAutosize from 'react-textarea-autosize'
import * as Tooltip from '@radix-ui/react-tooltip'
import { useModulesContext } from '../../contexts/ModulesContext'

interface CowriterProps {
  show?: boolean
}

const Cowriter: React.FC<CowriterProps> = ({ show = false }) => {
  const { isCowriterOpen, closeCowriter } = useModulesContext()

  show = isCowriterOpen

  const translateWordClassName = show
    ? styles.options
    : `${styles.options} ${styles.hidden}`

  return (
    <div className={translateWordClassName}>
      <div className={styles.top}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div className={styles.title}>Cowriter</div>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <IconButton size="s" onClick={closeCowriter}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    className={styles.TooltipContent}
                    sideOffset={5}
                  >
                    Close
                    <Tooltip.Arrow className={styles.TooltipArrow} />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
        </div>
        <div className={`${styles.wrapper} ${styles.paddingBottom}`}></div>
      </div>
      <div className={styles.content}>Under Constraction...</div>
    </div>
  )
}

export default Cowriter
