import React, {
  ReactNode,
  ReactEventHandler,
  useEffect,
  useRef,
  // useState,
} from 'react'
import styles from './Form.module.css'
import Notice from './Notice'
// import * as Toast from '@radix-ui/react-toast'

const Form = (props: {
  children: ReactNode
  checkForValidityOn: Object[]
  errorMessage?: string | null
  onSubmit: ReactEventHandler
  validityCallback: (isValid: boolean) => any
}) => {
  const {
    children,
    checkForValidityOn,
    errorMessage,
    onSubmit,
    validityCallback,
  } = props
  const formRef = useRef<HTMLFormElement>(null)
  // const [open, setOpen] = useState(!!errorMessage)

  const checkFormValidity = () => {
    if (typeof validityCallback === 'function' && formRef.current)
      validityCallback(formRef.current.checkValidity())
  }

  useEffect(() => {
    checkFormValidity()
    // setOpen(!!errorMessage)
  }, checkForValidityOn) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form className={styles.wrapper} ref={formRef} onSubmit={onSubmit}>
      {errorMessage && (
        <Notice>{errorMessage}</Notice>
        // <Toast.Provider swipeDirection="up">
        //   <Toast.Root
        //     className={styles.ToastRoot}
        //     open={open}
        //     onOpenChange={setOpen}
        //     duration={30000}
        //   >
        //     <Toast.Title className={styles.ToastTitle}>
        //       {errorMessage}
        //     </Toast.Title>
        //     {/* <Toast.Description>Use command + V to past it</Toast.Description> */}
        //     <Toast.Action
        //       className={styles.ToastAction}
        //       asChild
        //       altText="Goto schedule to undo"
        //     >
        //       <button className="Button small green">Ok</button>
        //     </Toast.Action>
        //   </Toast.Root>
        //   <Toast.Viewport className={styles.ToastViewport} />
        // </Toast.Provider>
      )}
      {children}
    </form>
  )
}

Form.defaultProps = {
  checkForValidityOn: [],
}

export default Form
