import React, { forwardRef } from 'react'
import styles from './DangerZone.module.css'
import Button from '../../ui-kit/Button'
// import IconButton from '../../ui-kit/IconButton'
import Divider from '../../ui-kit/Divider'

// import { LeftArrowIcon } from '../../assets'

interface DangerZoneProps {
  onBackClick: () => void
}

const DangerZone = forwardRef<HTMLButtonElement, DangerZoneProps>(
  (props, ref) => {
    // const { onBackClick } = props

    return (
      <div className={styles.wrapper}>
        {/* <div className={styles.top}>
          <IconButton secondary onClick={onBackClick}>
            <LeftArrowIcon />
          </IconButton>
        </div> */}
        <div className={styles.content}>
          <div className={styles.titleWraper}>
            <div className={styles.title}>Danger Zone</div>
            <div className={styles.subtitle}>
              Be in all attention what you may do here!
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.actionWrapper}>
              <div>
                <div className={styles.text1}>Delete your data from Diglot</div>
                <div className={styles.text2}>
                  You may restore all data during 2 weeks
                </div>
              </div>
              <Button size="s" kind="warning" ref={ref}>
                Delete all Notes
              </Button>
            </div>
            <Divider direction="H" />
            <div className={styles.actionWrapper}>
              <div>
                <div className={styles.text1}>Delete your data from Diglot</div>
                <div className={styles.text2}>
                  You may restore all data during 2 weeks
                </div>
              </div>
              <Button size="s" kind="warning">
                Delete Account
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default DangerZone
