import { useEffect, useCallback } from 'react';
import { appDispatch, createNoteAndSetCurrent } from '../store';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../store';

export const useCtrlNHotkey = () => {
  const currentUser = useSelector(selectCurrentUser);

  const createNewNote = useCallback(() => {
    if (currentUser) {
      appDispatch(
        createNoteAndSetCurrent({
          userID: currentUser.id,
          date: new Date().toISOString(),
        })
      );
    }
  }, [currentUser]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'n') {
        createNewNote();
      }
    },
    [createNewNote]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};
