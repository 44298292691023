import React from 'react'
import styles from './Toolbar.module.css'
import Divider from '../ui-kit/Divider'
import LangSelect from './components/LangSelect'
import NoteMenu from './components/NoteMenu'
import NoteStatus from './components/NoteStatus'
import CopyAllText from './components/CopyAllText'
import Spinner from '../ui-kit/Spinner'
import SendMail from './components/SendMail'
import * as Tooltip from '@radix-ui/react-tooltip'

import { useSelector } from 'react-redux'
import { useEffect, useCallback } from 'react'
import { debounce } from 'lodash'

import {
  appDispatch,
  NoteLike,
  postNote,
  selectCurrentNote,
  selectIsSaving,
  setIsSaving,
  store,
} from '../store'
import EditorToolbar from './components/EditorToolbar'

interface ToolbarProps {
  size: 'slim' | 'wide'
}

const Toolbar: React.FC<ToolbarProps> = (props: ToolbarProps) => {
  const delayedPostNote = useCallback((note: NoteLike) => {
    const debouncedFunction = debounce((noteToPost: NoteLike) => {
      if (noteToPost) {
        appDispatch(postNote(noteToPost))
      }
    }, 1000)
    debouncedFunction(note)
  }, [])

  const isNoteSaving = useSelector(selectIsSaving)
  const note = useSelector(selectCurrentNote)

  useEffect(() => {
    if (note) {
      !selectIsSaving(store.getState()) && appDispatch(setIsSaving(true))
      delayedPostNote(note)
    }
  }, [note, delayedPostNote])

  return (
    <>
      <div
        className={
          props.size === 'slim' ? styles.toolbarWrapper : styles.toolbarWide
        }
      >
        <div
          className={
            props.size === 'slim' ? styles.toolbarSlim : styles.pageMenuWide
          }
        >
          <div className={styles.pageMenuConteinerWide}>
            <div
              className={
                props.size === 'slim'
                  ? styles.pageMenuNav
                  : styles.pageMenuNavWide
              }
            >
              <EditorToolbar />
            </div>
          </div>

          <div
            className={
              props.size === 'slim'
                ? styles.pageMenuLang
                : styles.pageMenuLangWide
            }
          >
            <div className={styles.dividerLang}>
              <Divider direction="V" />
            </div>
            <LangSelect />
          </div>
        </div>
      </div>
      {/* Right menu */}
      <div className={styles.menuRight}>
        {isNoteSaving ? <Spinner size="s" color="secondary" /> : <NoteStatus />}

        <div className={styles.dividerPad}>
          <Divider direction="V" />
        </div>
        <div className={styles.extraButtons}>
          <CopyAllText />
          <SendMail />
        </div>

        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <span>
                <NoteMenu />
              </span>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
                Note Options
                <Tooltip.Arrow className={styles.TooltipArrow} />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
    </>
  )
}

export default Toolbar
