// src/slate/components/Leaf.tsx
import React, { ReactNode, FC, HTMLAttributes, MouseEvent } from 'react'
import style from '../../editor/components/NoteBody.module.css'
import { useEditorContext } from '../../contexts/EditorContext'
import { useModulesContext } from '../../contexts/ModulesContext'
import { ReactEditor } from 'slate-react'
import { Editor, Node, Text } from 'slate'

interface LeafProps {
  attributes: HTMLAttributes<HTMLElement> & {
    'data-slate-leaf'?: boolean
  }
  children: ReactNode
  leaf: any
}

const Leaf: FC<LeafProps> = ({ attributes, children, leaf }) => {
  const { setFirstTime, setSavedSelection, editor } = useEditorContext()

  const { isTranslateWordOpen, openTranslateText, closeTranslateWord } =
    useModulesContext()

  const handleMouseDown = (event: MouseEvent) => {
    let target = event.target as HTMLElement
    while (target && !target.classList.contains(style.foreignLanguage)) {
      target = target.parentElement!
    }

    if (target && target.classList.contains(style.foreignLanguage)) {
      const text = target.innerText

      if (editor) {
        console.log('start setSavedSelection')
        // Используем Slate API для поиска узла и пути, которые соответствуют выбранному элементу
        const slateNode = ReactEditor.toSlateNode(editor as ReactEditor, target)
        const path = ReactEditor.findPath(editor as ReactEditor, slateNode)
        const start = Editor.start(editor, path)
        const end = Editor.end(editor, path)

        if (start && end) {
          const newRange = { anchor: start, focus: end }
          console.log('newRange', newRange)

          // Сохраняем этот диапазон в вашем контексте
          setSavedSelection(newRange)
        }
      }

      if (isTranslateWordOpen) {
        closeTranslateWord()
        openTranslateText(text)
      } else {
        openTranslateText(text)
      }
      setFirstTime(false)
    }
  }

  const handleKeyUp = () => {
    console.log('handleKeyUp called')
    if (editor) {
      const textNodes = Array.from(Node.descendants(editor)).filter(([node]) =>
        Text.isText(node)
      )
      const textContent = textNodes
        .map(([node]) => (node as Text).text)
        .join(' ')

      const words = textContent.trim().split(' ')
      const lastWord = words[words.length - 1]

      console.log('textContent:', textContent)
      console.log('lastWord:', lastWord)
    }
  }

  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.foreignLanguage) {
    return (
      <span
        {...attributes}
        className={style.foreignLanguage}
        onMouseDown={handleMouseDown}
        onKeyUp={handleKeyUp}
      >
        {children}
      </span>
    )
  }

  if (leaf.nativeLanguage) {
    return (
      <span {...attributes} className={style.nativeLanguage}>
        {children}
      </span>
    )
  }

  if (leaf.warningLanguage) {
    return (
      <span {...attributes} className={style.warningLanguage}>
        {children}
      </span>
    )
  }

  return <span {...attributes}>{children}</span>
}

export default Leaf
