import React from 'react'
import styles from './OptionLayout.module.css'
import { useEditorContext } from '../../contexts/EditorContext'

interface OptionLayoutProps {
  children: React.ReactNode
}

const OptionLayout: React.FC<OptionLayoutProps> = ({ children }) => {
  const { isItFirstTime } = useEditorContext()

  if (isItFirstTime) {
    return null
  }

  return <div className={styles.Placeholder}>{children}</div>
}

export default OptionLayout
