import React from 'react'
import styles from './LangButton.module.css'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { OkIcon } from '../../assets'
import { languages } from '../../utilities/languages'

interface LangButtonProps {
  currentLanguage: string
  onLanguageChange: (newLanguage: string) => void
}

function LangButton({ currentLanguage, onLanguageChange }: LangButtonProps) {
  const handleLanguageChange = (newLanguage: string) => {
    onLanguageChange(newLanguage)
  }

  const currentLanguageInfo = languages[currentLanguage]
  const currentLanguageName = currentLanguageInfo
    ? currentLanguageInfo.name
    : currentLanguage

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <div className={styles.wrapper}>{currentLanguageName}</div>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={styles.DropdownMenuContent}
          sideOffset={8}
          side="bottom"
          align="center"
          alignOffset={-4}
          hideWhenDetached={true}
        >
          <div className={styles.DropdownMenuContentWrapper}>
            {Object.keys(languages).map((languageKey) => {
              const language = languages[languageKey]
              return (
                <DropdownMenu.Item
                  key={languageKey}
                  className={styles.DropdownMenuItem}
                  onSelect={() => handleLanguageChange(languageKey)}
                >
                  {currentLanguage === languageKey ? (
                    <OkIcon className={styles.icon} />
                  ) : (
                    <div className={styles.icon}></div>
                  )}
                  {language.name}
                </DropdownMenu.Item>
              )
            })}
          </div>

          <DropdownMenu.Arrow className={styles.DropdownMenuArrow} />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default LangButton
