import React from 'react'
import styles from './Betatest.module.css'

function Betatest() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.currentPlan}>
        <span className={styles.planName}>Private Beta</span>
        <span className={styles.words}>• v0.0.1</span>
      </div>
      <div className={styles.words}>Some features may not work</div>
    </div>
  )
}

export default Betatest
