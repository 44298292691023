import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styles from './Avatar.module.css'
import Logo from '../ui-kit/DiglotLogo'
import { RootState } from '../store/index'

type AvatarProps = {
  size?: 's' | 'm' | 'l'
  photoURL?: string
}

const getSizeDimensions = (size: string) => {
  switch (size) {
    case 's':
      return { width: '24px', height: '24px' }
    case 'm':
      return { width: '32px', height: '32px' }
    case 'l':
      return { width: '48px', height: '48px' }
    default:
      return { width: '24px', height: '24px' }
  }
}

const Avatar: React.FC<AvatarProps> = ({ size = 's', photoURL }) => {
  const currentUser = useSelector((state: RootState) => state.currentUser)
  const avatarSrc = photoURL || currentUser?.photoURL
  // const avatarSrc = currentUser?.photoURL
  const dimensions = getSizeDimensions(size)

  // console.log('avatarSrc', avatarSrc)

  const viewBoxSize = {
    width: parseInt(dimensions.width, 10),
    height: parseInt(dimensions.height, 10),
  }

  const uniqueId = useMemo(
    () => `svgmask_${Math.random().toString(36).substr(2, 9)}`,
    []
  )

  const getMaskSVG = (size: string): JSX.Element => {
    switch (size) {
      case 's':
        return (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
            <path
              id="avatarMaskM"
              d="M23.3732 5.93146C23.3732 5.93146 22.6016 2.24299 19.2662 0.947041C17.6732 0.323988 14.8107 0.0249221 11.998 0C9.18538 0.0249221 6.32293 0.323988 4.7548 0.922118C1.41942 2.21807 0.647808 5.90654 0.647808 5.90654C0.14999 8 -0.0242456 10.1184 0.000645298 11.9875C-0.0242456 13.8816 0.1251 16 0.647808 18.0935C0.647808 18.0935 1.41942 21.7819 4.7548 23.0779C6.32293 23.676 9.18538 23.9751 11.998 24C14.8107 23.9751 17.6732 23.7009 19.2413 23.0779C22.5767 21.7819 23.3483 18.0935 23.3483 18.0935C23.8461 16 24.0203 13.8816 23.9954 12.0125C24.0203 10.1184 23.871 8 23.3732 5.93146Z"
            />
          </svg>
        )
      case 'm':
        return (
          <svg width="32" height="32" viewBox="0 0 24 24" fill="white">
            <path
              id="avatarMaskM"
              d="M23.3732 5.93146C23.3732 5.93146 22.6016 2.24299 19.2662 0.947041C17.6732 0.323988 14.8107 0.0249221 11.998 0C9.18538 0.0249221 6.32293 0.323988 4.7548 0.922118C1.41942 2.21807 0.647808 5.90654 0.647808 5.90654C0.14999 8 -0.0242456 10.1184 0.000645298 11.9875C-0.0242456 13.8816 0.1251 16 0.647808 18.0935C0.647808 18.0935 1.41942 21.7819 4.7548 23.0779C6.32293 23.676 9.18538 23.9751 11.998 24C14.8107 23.9751 17.6732 23.7009 19.2413 23.0779C22.5767 21.7819 23.3483 18.0935 23.3483 18.0935C23.8461 16 24.0203 13.8816 23.9954 12.0125C24.0203 10.1184 23.871 8 23.3732 5.93146Z"
            />
          </svg>
        )
      case 'l':
        return (
          <svg width="48" height="48" viewBox="0 0 24 24" fill="white">
            <path
              id="avatarMaskM"
              d="M23.3732 5.93146C23.3732 5.93146 22.6016 2.24299 19.2662 0.947041C17.6732 0.323988 14.8107 0.0249221 11.998 0C9.18538 0.0249221 6.32293 0.323988 4.7548 0.922118C1.41942 2.21807 0.647808 5.90654 0.647808 5.90654C0.14999 8 -0.0242456 10.1184 0.000645298 11.9875C-0.0242456 13.8816 0.1251 16 0.647808 18.0935C0.647808 18.0935 1.41942 21.7819 4.7548 23.0779C6.32293 23.676 9.18538 23.9751 11.998 24C14.8107 23.9751 17.6732 23.7009 19.2413 23.0779C22.5767 21.7819 23.3483 18.0935 23.3483 18.0935C23.8461 16 24.0203 13.8816 23.9954 12.0125C24.0203 10.1184 23.871 8 23.3732 5.93146Z"
            />
          </svg>
        )
      default:
        return (
          <svg width="32" height="32" viewBox="0 0 24 24" fill="white">
            <path
              id="avatarMaskM"
              d="M23.3732 5.93146C23.3732 5.93146 22.6016 2.24299 19.2662 0.947041C17.6732 0.323988 14.8107 0.0249221 11.998 0C9.18538 0.0249221 6.32293 0.323988 4.7548 0.922118C1.41942 2.21807 0.647808 5.90654 0.647808 5.90654C0.14999 8 -0.0242456 10.1184 0.000645298 11.9875C-0.0242456 13.8816 0.1251 16 0.647808 18.0935C0.647808 18.0935 1.41942 21.7819 4.7548 23.0779C6.32293 23.676 9.18538 23.9751 11.998 24C14.8107 23.9751 17.6732 23.7009 19.2413 23.0779C22.5767 21.7819 23.3483 18.0935 23.3483 18.0935C23.8461 16 24.0203 13.8816 23.9954 12.0125C24.0203 10.1184 23.871 8 23.3732 5.93146Z"
            />
          </svg>
        )
    }
  }

  return (
    <>
      {avatarSrc ? (
        <div className={styles.appLogo} style={dimensions}>
          <svg
            width={dimensions.width}
            height={dimensions.height}
            viewBox={`0 0 ${viewBoxSize.width} ${viewBoxSize.height}`}
          >
            <mask id={`mask_${uniqueId}`}>
              <rect
                width={viewBoxSize.width}
                height={viewBoxSize.height}
                fill="black"
              />
              {getMaskSVG(size)}
            </mask>
            <image
              width={viewBoxSize.width}
              height={viewBoxSize.height}
              xlinkHref={avatarSrc}
              mask={`url(#mask_${uniqueId})`}
              preserveAspectRatio="xMidYMid meet"
            />
          </svg>
        </div>
      ) : (
        <div style={dimensions}>
          <Logo className={`${styles.appLogo} ${styles[size]}`} />
        </div>
      )}
    </>
  )
}

export default Avatar
