interface LanguageInfo {
  name: string
  detectLang: string
  deeplSource: string
  deeplTarget: string
  googleCode: string
  detectFranc: string
}

export const languages: { [name: string]: LanguageInfo } = {
  Ukrainian: {
    name: 'Українська',
    detectLang: 'uk',
    deeplSource: 'UK',
    deeplTarget: 'UK',
    googleCode: 'uk',
    detectFranc: 'ukr',
  },
  English: {
    name: 'English',
    detectLang: 'en',
    deeplSource: 'EN',
    deeplTarget: 'EN-US',
    googleCode: 'en',
    detectFranc: 'eng',
  },
  Spanish: {
    name: 'Español',
    detectLang: 'es',
    deeplSource: 'ES',
    deeplTarget: 'ES',
    googleCode: 'es',
    detectFranc: 'esp',
  },
  Italian: {
    name: 'Italiano',
    detectLang: 'it',
    deeplSource: 'IT',
    deeplTarget: 'IT',
    googleCode: 'it',
    detectFranc: 'itl',
  },
  Polish: {
    name: 'Polski',
    detectLang: 'pl',
    deeplSource: 'PL',
    deeplTarget: 'PL',
    googleCode: 'pl',
    detectFranc: 'pol',
  },
  German: {
    name: 'Deutsch',
    detectLang: 'de',
    deeplSource: 'DE',
    deeplTarget: 'DE',
    googleCode: 'de',
    detectFranc: 'deu',
  },
  French: {
    name: 'Français',
    detectLang: 'fr',
    deeplSource: 'FR',
    deeplTarget: 'FR',
    googleCode: 'fr',
    detectFranc: 'fra',
  },
  Portuguese: {
    name: 'Português',
    detectLang: 'pt',
    deeplSource: 'PT',
    deeplTarget: 'PT-PT',
    googleCode: 'pt',
    detectFranc: 'por',
  },
  Bulgarian: {
    name: 'Български',
    detectLang: 'bg',
    deeplSource: 'BG',
    deeplTarget: 'BG',
    googleCode: 'bg',
    detectFranc: 'bul',
  },
  Czech: {
    name: 'Čeština',
    detectLang: 'cs',
    deeplSource: 'CS',
    deeplTarget: 'CS',
    googleCode: 'cs',
    detectFranc: 'cze',
  },
  // Danish: {
  //   name: 'Dansk',
  //   detectLang: 'da',
  //   deeplSource: 'DA',
  //   deeplTarget: 'DA',
  //   googleCode: 'da',
  // },

  Greek: {
    name: 'Ελληνικά',
    detectLang: 'el',
    deeplSource: 'EL',
    deeplTarget: 'EL',
    googleCode: 'el',
    detectFranc: 'gre',
  },

  // Estonian: {
  //   name: 'Eesti',
  //   detectLang: 'et',
  //   deeplSource: 'ET',
  //   deeplTarget: 'ET',
  //   googleCode: 'et',
  // },
  // Finnish: {
  //   name: 'Suomi',
  //   detectLang: 'fi',
  //   deeplSource: 'FI',
  //   deeplTarget: 'FI',
  //   googleCode: 'fi',
  // },
  // Hungarian: {
  //   name: 'Magyar',
  //   detectLang: 'hu',
  //   deeplSource: 'HU',
  //   deeplTarget: 'HU',
  //   googleCode: 'hu',
  // },

  Japanese: {
    name: '日本語',
    detectLang: 'ja',
    deeplSource: 'JA',
    deeplTarget: 'JA',
    googleCode: 'ja',
    detectFranc: 'jpn',
  },
  Korean: {
    name: '한국어',
    detectLang: 'ko',
    deeplSource: 'KO',
    deeplTarget: 'KO',
    googleCode: 'ko',
    detectFranc: 'kor',
  },
  // Lithuanian: {
  //   name: 'Lietuvių',
  //   detectLang: 'lt',
  //   deeplSource: 'LT',
  //   deeplTarget: 'LT',
  //   googleCode: 'lt',
  // },
  // Latvian: {
  //   name: 'Latviešu',
  //   detectLang: 'lv',
  //   deeplSource: 'LV',
  //   deeplTarget: 'LV',
  //   googleCode: 'lv',
  // },
  // Norwegian: {
  //   name: 'Norsk',
  //   detectLang: 'nb',
  //   deeplSource: 'NB',
  //   deeplTarget: 'NB',
  //   googleCode: 'no',
  // },
  // Dutch: {
  //   name: 'Nederlands',
  //   detectLang: 'nl',
  //   deeplSource: 'NL',
  //   deeplTarget: 'NL',
  //   googleCode: 'nl',
  // },
  // Romanian: {
  //   name: 'Română',
  //   detectLang: 'ro',
  //   deeplSource: 'RO',
  //   deeplTarget: 'RO',
  //   googleCode: 'ro',
  // },
  // Russian: {
  //   name: 'Русский',
  //   detectLang: 'ru',
  //   deeplSource: 'RU',
  //   deeplTarget: 'RU',
  //   googleCode: 'ru',
  // },
  // Slovak: {
  //   name: 'Slovenčina',
  //   detectLang: 'sk',
  //   deeplSource: 'SK',
  //   deeplTarget: 'SK',
  //   googleCode: 'sk',
  // },
  // Slovenian: {
  //   name: 'Slovenščina',
  //   detectLang: 'sl',
  //   deeplSource: 'SL',
  //   deeplTarget: 'SL',
  //   googleCode: 'sl',
  // },
  // Swedish: {
  //   name: 'Svenska',
  //   detectLang: 'sv',
  //   deeplSource: 'SV',
  //   deeplTarget: 'SV',
  //   googleCode: 'sv',
  // },
  Turkish: {
    name: 'Türkçe',
    detectLang: 'tr',
    deeplSource: 'TR',
    deeplTarget: 'TR',
    googleCode: 'tr',
    detectFranc: 'tur',
  },

  Chinese: {
    name: '中文',
    detectLang: 'zh',
    deeplSource: 'ZH',
    deeplTarget: 'ZH',
    googleCode: 'zh-CN',
    detectFranc: 'chi',
  },
  Indonesian: {
    name: 'Bahasa Indonesia',
    detectLang: 'id',
    deeplSource: 'ID',
    deeplTarget: 'ID',
    googleCode: 'id',
    detectFranc: 'ind',
  },
}
