import React from 'react'
import * as Tooltip from '@radix-ui/react-tooltip'
import IconButton from '../../ui-kit/IconButton'
import styles from './SendMail.module.css'
import { SendIcon } from '../../assets'
import { useEditorContext } from '../../contexts/EditorContext'

function SendMail() {
  const { editor } = useEditorContext()

  const serializeToHTML = (nodes: any[]): string => {
    return nodes
      .map((node: any) => {
        if ('text' in node) {
          return node.text
        }

        const children = serializeToHTML(node.children)

        switch (node.type) {
          case 'paragraph':
            return `<p>${children}</p>`
          case 'bold':
            return `<strong>${children}</strong>`
          case 'italic':
            return `<em>${children}</em>`
          // другие типы нод
          default:
            return children
        }
      })
      .join('')
  }

  const openEmailClient = () => {
    if (editor && editor.children) {
      const htmlContent = encodeURIComponent(serializeToHTML(editor.children))
      window.location.href = `mailto:?subject=Hello&body=${htmlContent}`
    }
  }

  return (
    <>
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <span>
              <IconButton onClick={openEmailClient}>
                <SendIcon />
              </IconButton>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
              Send via email
              <Tooltip.Arrow className={styles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </>
  )
}

export default SendMail
