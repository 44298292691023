import styles from './SignInbyMail.module.css'
import Button from '../ui-kit/Button'
import Input from '../ui-kit/Input'
import Divider from '../ui-kit/Divider'
import Form from '../ui-kit/Form'

import { appDispatch, signIn, selectError } from '../store'
import { ReactEventHandler, useState } from 'react'
import { connect, useSelector } from 'react-redux'

const SignInScene = () => {
  const [isValid, setIsValid] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const error = useSelector(selectError)

  const handleSumbit: ReactEventHandler = (event) => {
    event.preventDefault()

    appDispatch(signIn({ email, password }))
  }

  return (
    <div className={styles.column}>
      <div className={styles.wrapper}>
        <Divider direction="H" />
        <div className={styles.text}>Or Sign In by Email</div>
        <Divider direction="H" />
      </div>
      <Form
        checkForValidityOn={[email, password]}
        errorMessage={error}
        validityCallback={setIsValid}
        onSubmit={handleSumbit}
      >
        <Input
          label="Email"
          type="email"
          isRequired
          value={email}
          onChange={setEmail}
        />
        <Input
          label="Password"
          type="password"
          isRequired
          value={password}
          onChange={setPassword}
        />
        <Button kind={isValid ? 'primary' : 'disabled'}>Sign in</Button>
      </Form>
    </div>
  )
}

export default connect()(SignInScene)
