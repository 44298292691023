import React, {
  ReactEventHandler,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react'
import styles from './Sidebar.module.css'
import * as Tooltip from '@radix-ui/react-tooltip'
import IconButton from '../ui-kit/IconButton'
import Button from '../ui-kit/Button'
import Divider from '../ui-kit/Divider'
import User from './components/User'
import Teaser from './components/Teaser'
import HelpMenuItem from './components/HelpMenuItem'
import PlanTeaser from './components/PlanTeaser'
import Motion from '../ui-kit/Motion'
import Notification from '../ui-kit/Notification'
import Betatest from './components/Betatest'

import { AnimatePresence } from 'framer-motion'
import {
  LeftDoubleArrowIcon,
  BarsIcon,
  SearchIcon,
  CommandIcon,
} from '../assets'

import { useSelector } from 'react-redux'
import { noteConverter } from '../utilities/helpers'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { firestore } from '../services/firebase'
import {
  appDispatch,
  createNoteAndSetCurrent,
  destroyError,
  NoteLike,
  selectCurrentNote,
  selectCurrentUser,
  selectError,
  selectNotes,
  setCurrentNote,
} from '../store'
import { useCtrlNHotkey } from '../utilities/hotkeyUtils'

const useNotesSubscription = (currentUserId: string) => {
  useEffect(() => {
    const notesQuery = query(
      collection(firestore, 'notes').withConverter(noteConverter),
      where('noteUserID', '==', currentUserId)
    )

    return onSnapshot(notesQuery, (notesSnapshot) => {
      let notes: NoteLike[] = []

      notesSnapshot.forEach((noteSnapshot) => {
        notes = [...notes, noteSnapshot.data()]
      })

      if (notes.length > 0) {
        appDispatch({
          type: 'notes/fetchNotes/fulfilled',
          payload: notes,
        })
        notes = []
      }
    })
  }, [currentUserId])
}

const Sidebar: React.FC<{ show?: boolean }> = ({ show = true }) => {
  const currentUser = useSelector(selectCurrentUser)
  const notes = useSelector(selectNotes)
  const note = useSelector(selectCurrentNote)
  const currentNoteID = note?.id

  // Обработчик нажатия клавиш
  useCtrlNHotkey()

  const [isSidebarVisible, setSidebarVisible] = useState(
    window.innerWidth >= 1104
  )

  useEffect(() => {
    if (window.innerWidth < 650) {
      setSidebarVisible(false)
    }
  }, [])

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible)
  }

  // Close sidebar on window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1340 && isSidebarVisible) {
        setSidebarVisible(false)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isSidebarVisible])

  useNotesSubscription(currentUser!.id)

  const handleNewNote: ReactEventHandler = (event) => {
    event.preventDefault()
    appDispatch(
      createNoteAndSetCurrent({
        userID: currentUser!.id,
        date: new Date().toISOString(),
      })
    )
    if (window.innerWidth < 640) {
      setSidebarVisible(false)
    }
  }

  const handleSetCurrentNote = (note: NoteLike, event: SyntheticEvent) => {
    event.preventDefault()
    appDispatch(setCurrentNote({ noteID: note.id }))
    if (window.innerWidth < 640) {
      setSidebarVisible(false)
    }
  }

  const [showNotification, setShowNotification] = useState(false)
  const error = useSelector(selectError)

  const handleCloseNotification = () => {
    setShowNotification(false)
    appDispatch(destroyError())
  }

  useEffect(() => {
    if (error) {
      setShowNotification(true)
    }
  }, [error])

  if (!isSidebarVisible) {
    return (
      <>
        <Notification
          title="An Error Occurred"
          description={error || undefined}
          open={showNotification}
          onOpenChange={handleCloseNotification}
          warning={true}
        />

        <div className={styles.hideSidebar}>
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <span>
                  <IconButton onClick={() => setSidebarVisible(true)}>
                    <BarsIcon />
                  </IconButton>
                </span>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content
                  className={styles.TooltipContent}
                  sideOffset={5}
                >
                  Show Sidebar
                  <Tooltip.Arrow className={styles.TooltipArrow} />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
      </>
    )
  }

  return (
    <>
      <Notification
        title="An Error Occurred"
        description={error || undefined}
        open={showNotification}
        onOpenChange={handleCloseNotification}
        warning={true}
      />

      <div className={styles.wrapper}>
        <div className={styles.sidebar}>
          <div className={styles.sidebarTop}>
            <div className={styles.sidebarHeader}>
              <User />
              <div className={styles.hideIcon}>
                <Tooltip.Provider>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <span>
                        <IconButton onClick={toggleSidebar}>
                          <LeftDoubleArrowIcon />
                        </IconButton>
                      </span>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className={styles.TooltipContent}
                        sideOffset={5}
                      >
                        Hide Sidebar
                        <Tooltip.Arrow className={styles.TooltipArrow} />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div>
            </div>
            <div className={styles.sidebarActions}>
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger className={styles.TooltipTriger} asChild>
                    <span>
                      <Button
                        kind="secondary"
                        size="s"
                        isFullWidth
                        onClick={handleNewNote}
                      >
                        NEW NOTE
                      </Button>
                    </span>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content
                      className={styles.TooltipContent}
                      sideOffset={5}
                    >
                      Create a new note
                      <div className={styles.RightSlot}>Ctrl+N</div>
                      <Tooltip.Arrow className={styles.TooltipArrow} />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>

              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <span>
                      <IconButton primary>
                        <SearchIcon />
                      </IconButton>
                    </span>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content
                      className={styles.TooltipContent}
                      sideOffset={5}
                    >
                      Open Search bar
                      <div className={styles.RightSlot}>⌘+S</div>
                      <Tooltip.Arrow className={styles.TooltipArrow} />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <span>
                      <IconButton primary>
                        <CommandIcon />
                      </IconButton>
                    </span>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content
                      className={styles.TooltipContent}
                      sideOffset={5}
                    >
                      Open Omnibar
                      <div className={styles.RightSlot}>⌘+/</div>
                      <Tooltip.Arrow className={styles.TooltipArrow} />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            </div>
          </div>
          <div className={styles.sideBarScroll}>
            <div className={styles.topGrad}></div>
            <div className={styles.teaserPlaceholder}></div>
            <AnimatePresence>
              {notes.map((note) => (
                <Motion kind="slideFromLeft" key={`motion${note.id}`}>
                  <Teaser
                    isActive={note.id === currentNoteID}
                    title={note.title}
                    date={note.lastModifiedDate}
                    onClick={(event) => {
                      handleSetCurrentNote(note, event)
                    }}
                    key={note.id}
                  />
                </Motion>
              ))}
            </AnimatePresence>
            <div className={styles.teaserPlaceholder}></div>
            <div className={styles.bottomGrad}></div>
          </div>
          <div className={styles.sidebarBottom}>
            <Betatest />
            <PlanTeaser />
            <div className={styles.divider}>
              <Divider direction="H" />
            </div>
            <HelpMenuItem />
          </div>
        </div>
        <div className={styles.backGround} onClick={toggleSidebar}></div>
      </div>
    </>
  )
}

export default Sidebar
