import * as React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { TrashIcon, CloseIcon } from '../assets'
// import './Trash.module.css'
import styles from './Trash.module.css'
import Divider from '../ui-kit/Divider'
import Button from '../ui-kit/Button'
import DeletedNote from './components/DeletedNote'

interface TrashProps {
  onSelect: () => void
  onOpenChange: (open: boolean) => void
}

const Trash: React.FC<TrashProps> = ({ onSelect, onOpenChange }) => {
  return (
    <Dialog.Root onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        <DropdownMenu.Item
          className={styles.item}
          onSelect={(event) => {
            event.preventDefault()
            onSelect()
          }}
        >
          <TrashIcon className={styles.icon} />
          Trash
        </DropdownMenu.Item>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.DialogOverlay} />
        <Dialog.Content className={styles.DialogContent}>
          <Dialog.Title className={styles.DialogTitle}>
            Trash
            <Dialog.Close asChild>
              <button className={styles.IconButton} aria-label="Close">
                <CloseIcon className={styles.icon} />
              </button>
            </Dialog.Close>
          </Dialog.Title>
          <Divider direction="H" />
          <div className={styles.content}>
            <div className={styles.sidebar}>
              <div className={styles.allNotes}>
                <div>You have 8 notes</div>
                <DeletedNote
                  active={true}
                  title="My custom title"
                  body="My custom body text"
                />
                <DeletedNote
                  active={false}
                  title="My custom title"
                  body="My custom body text"
                />
                <DeletedNote
                  active={false}
                  title="My custom title"
                  body="My custom body text"
                />{' '}
                <DeletedNote
                  active={false}
                  title="My custom title"
                  body="My custom body text"
                />{' '}
                <DeletedNote
                  active={false}
                  title="My custom title"
                  body="My custom body text"
                />{' '}
                <DeletedNote
                  active={false}
                  title="My custom title"
                  body="My custom body text"
                />{' '}
                <DeletedNote
                  active={false}
                  title="My custom title"
                  body="My custom body text"
                />{' '}
                <DeletedNote
                  active={false}
                  title="My custom title"
                  body="My custom body text"
                />{' '}
                <DeletedNote
                  active={false}
                  title="My custom title"
                  body="My custom body text"
                />{' '}
                <DeletedNote
                  active={false}
                  title="My custom title"
                  body="My custom body text"
                />
              </div>
              <Divider direction="H" />
              <div className={styles.allNotesActions}>
                <div className={styles.allNotesActionsText}>
                  Notes stay in your trash for two weeks before being
                  permanently deleted.
                </div>
                <Button size="s" kind="secondary" isFullWidth>
                  Restore all notes
                </Button>
                <Button size="s" kind="warning" isFullWidth>
                  Empty trash
                </Button>
              </div>
            </div>
            <Divider direction="V" />
            <div className={styles.contentWrapper}>
              <Dialog.Description className={styles.DialogDescription}>
                Are you sure you want to delete this record?
              </Dialog.Description>
              <div className={styles.note}>
                <div className={styles.noteTitle}>
                  My first text written with Diglot.ai
                </div>
                <div className={styles.noteBody}>
                  Are you looking for a tool to help you write in English more
                  easily and quickly? Look no further! The bilingual writing
                  tool is here to help. With this tool, you can easily write
                  notes in English thanks to the built-in intelligent
                  translator. This translator can help you find the right words
                  and phrases to use in your writing, so you can express
                  yourself clearly and accurately. But that's not all – the
                  bilingual writing tool also includes a double translator
                  feature, which allows you to check your texts for accuracy.
                  Simply enter your text, and the tool will translate it back
                  and forth between English and your chosen language. This can
                  help you catch any mistakes or inconsistencies in your
                  writing, ensuring that your message is conveyed clearly and
                  effectively. So why wait? Give the bilingual writing tool a
                  try today and start writing in English with confidence and
                  ease! Are you looking for a tool to help you write in English
                  more easily and quickly? Look no further! The bilingual
                  writing tool is here to help. With this tool, you can easily
                  write notes in English thanks to the built-in intelligent
                  translator. This translator can help you find the right words
                  and phrases to use in your writing, so you can express
                  yourself clearly and accurately. But that's not all – the
                  bilingual writing tool also includes a double translator
                  feature, which allows you to check your texts for accuracy.
                  Simply enter your text, and the tool will translate it back
                  and forth between English and your chosen language. This can
                  help you catch any mistakes or inconsistencies in your
                  writing, ensuring that your message is conveyed clearly and
                  effectively. So why wait? Give the bilingual writing tool a
                  try today and start writing in English with confidence and
                  ease!
                </div>
              </div>
              <Divider direction="H" />
              <div className={styles.buttonWrapper}>
                <Button size="s" kind="warning">
                  Delete parmanently
                </Button>
                <Button size="s" kind="secondary">
                  Restore note
                </Button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default Trash
