import React, { forwardRef, useContext } from 'react'
import styles from './Interface.module.css'
import Divider from '../../ui-kit/Divider'
import * as Select from '@radix-ui/react-select'
import classnames from 'classnames'
import {
  SunIcon,
  MoonIcon,
  DesktopIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  OkIcon,
} from '../../assets'

import { FontSizeContext } from '../../contexts/FontSizeContext'

const Interface = forwardRef<HTMLButtonElement>((props, ref) => {
  const { fontSize, setFontSize } = useContext(FontSizeContext) // Извлечение значений из контекста

  const handleFontSizeChange = (selectedValue: string) => {
    setFontSize(selectedValue) // Обновите состояние с новым значением
    localStorage.setItem('font-size', selectedValue) // Сохраните новое значение в localStorage
  }

  const doc = document.firstElementChild

  const setTheme = (theme: string): void => {
    if (doc) {
      doc.setAttribute('color-scheme', theme)
      localStorage.setItem('color-scheme', theme) // Сохранение темы в localStorage
    }
  }

  const handleThemeChange = (selectedValue: string) => {
    switch (selectedValue) {
      case 'auto':
        setTheme('auto')
        break
      case 'dark':
        setTheme('dark')
        break
      case 'light':
        setTheme('light')
        break
      default:
        break
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.blockFirst}>
        <div className={styles.blockTitle}>Appearance</div>
        <div className={styles.blockContent}>
          <div className={styles.contentleft}>
            <div className={styles.textFirst}>Color scheme</div>
            <div className={styles.textSecond}>Default App colors</div>
          </div>
          <div className={styles.contentright}>
            <Select.Root
              defaultValue={localStorage.getItem('color-scheme') || 'auto'}
              onValueChange={handleThemeChange}
            >
              <Select.Trigger
                className={styles.SelectTrigger}
                aria-label="Food"
                ref={ref}
              >
                <Select.Value placeholder="Auto" />
                <Select.Icon className={styles.SelectIcon}>
                  <ChevronDownIcon className={styles.icon} />
                </Select.Icon>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content
                  className={styles.SelectContent}
                  position="popper"
                  sideOffset={4}
                  align="center"
                >
                  <Select.ScrollUpButton className={styles.SelectScrollButton}>
                    <ChevronUpIcon className={styles.icon} />
                  </Select.ScrollUpButton>
                  <Select.Viewport className={styles.SelectViewport}>
                    <Select.Group>
                      <SelectItem value="auto">
                        <DesktopIcon className={styles.icon} /> System
                      </SelectItem>
                      <SelectItem value="dark">
                        <MoonIcon className={styles.icon} />
                        Dark
                      </SelectItem>
                      <SelectItem value="light">
                        <SunIcon className={styles.icon} />
                        Light
                      </SelectItem>
                    </Select.Group>
                  </Select.Viewport>
                  <Select.ScrollDownButton
                    className={styles.SelectScrollButton}
                  >
                    <ChevronDownIcon className={styles.icon} />
                  </Select.ScrollDownButton>
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          </div>
        </div>
      </div>
      <Divider direction="H" />
      <div className={styles.block}>
        <div className={styles.blockContent}>
          <div className={styles.contentleft}>
            <div className={styles.textFirst}>Sidebar</div>
            <div className={styles.textSecond}>Default Sidebar appearance</div>
          </div>
          <div className={styles.contentright}>
            <Select.Root defaultValue="show">
              <Select.Trigger
                className={styles.SelectTrigger}
                aria-label="Food"
              >
                <Select.Value placeholder="Show" />
                <Select.Icon className={styles.SelectIcon}>
                  <ChevronDownIcon className={styles.icon} />
                </Select.Icon>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content
                  className={styles.SelectContent}
                  position="popper"
                  sideOffset={4}
                  align="center"
                >
                  <Select.ScrollUpButton className={styles.SelectScrollButton}>
                    <ChevronUpIcon className={styles.icon} />
                  </Select.ScrollUpButton>
                  <Select.Viewport className={styles.SelectViewport}>
                    <Select.Group>
                      <SelectItem value="show">Show</SelectItem>
                      <SelectItem value="hide">Hide</SelectItem>
                    </Select.Group>
                  </Select.Viewport>
                  <Select.ScrollDownButton
                    className={styles.SelectScrollButton}
                  >
                    <ChevronDownIcon className={styles.icon} />
                  </Select.ScrollDownButton>
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          </div>
        </div>
      </div>
      <Divider direction="H" />
      <div className={styles.block}>
        <div className={styles.blockTitle}>Editor</div>
        <div className={styles.blockContent}>
          <div className={styles.contentleft}>
            <div className={styles.textFirst}>Font size</div>
            <div className={styles.textSecond}>Set base font for Diglot</div>
          </div>
          <div className={styles.contentright}>
            <Select.Root
              defaultValue={fontSize}
              onValueChange={handleFontSizeChange}
            >
              <Select.Trigger
                className={styles.SelectTrigger}
                aria-label="Font Size"
              >
                <Select.Value placeholder="Medium" />
                <Select.Icon className={styles.SelectIcon}>
                  <ChevronDownIcon className={styles.icon} />
                </Select.Icon>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content
                  className={styles.SelectContent}
                  position="popper"
                  sideOffset={4}
                  align="center"
                >
                  <Select.ScrollUpButton className={styles.SelectScrollButton}>
                    <ChevronUpIcon className={styles.icon} />
                  </Select.ScrollUpButton>
                  <Select.Viewport className={styles.SelectViewport}>
                    <Select.Group>
                      <SelectItem value="small">Small</SelectItem>
                      <SelectItem value="medium">Medium</SelectItem>
                      <SelectItem value="large">Large</SelectItem>
                    </Select.Group>
                  </Select.Viewport>
                  <Select.ScrollDownButton
                    className={styles.SelectScrollButton}
                  >
                    <ChevronDownIcon className={styles.icon} />
                  </Select.ScrollDownButton>
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          </div>
        </div>
      </div>
    </div>
  )
})

interface SelectItemProps {
  children: React.ReactNode
  childrenIcon?: React.ReactNode
  value: string
  className?: string
  disabled?: boolean
}

const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, childrenIcon, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classnames(styles.SelectItem, className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>
          <div className={styles.iconText}>
            {children}
            {childrenIcon}
          </div>
        </Select.ItemText>
        <Select.ItemIndicator className={styles.SelectItemIndicator}>
          <OkIcon className={styles.icon} />
        </Select.ItemIndicator>
      </Select.Item>
    )
  }
)

export default Interface
