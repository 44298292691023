import React from 'react'
import styles from './TranslatePlaceholder.module.css'

interface TranslatePlaceholderProps {
  show?: boolean
}

const TranslatePlaceholder: React.FC<TranslatePlaceholderProps> = ({
  show = false,
}) => {
  return <div className={`${show ? styles.options : styles.hide}`}></div>
}

export default TranslatePlaceholder
