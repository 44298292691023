import { Link } from 'react-router-dom'
import styles from './SignUp.module.css'
import SignUpbyMail from '../auth/SignUpbyMail'
import SignUpbyGoogle from '../auth/SignUpByGoogle'
import Copyright from '../ui-kit/Copyright'
import Logo from '../ui-kit/DiglotLogo'
import LinkTo from '../ui-kit/LinkTo'
import Motion from '../ui-kit/Motion'

const SignUp = () => {
  return (
    <Motion kind="slideFromBottom">
      <div className={styles.column}>
        <div className={styles.formHeader}>
          <a href="https://diglot.ai" className={styles.logoLink}>
            <Logo className={styles.appLogo} />
          </a>
          <h1>Sign up to Diglot.ai</h1>
          <p className="text--center">
            Diglot is a brand new all-in-one AI app that allows you to write in
            a English quickly.
          </p>
          <SignUpbyGoogle />
          <SignUpbyMail />
          <div className={styles.authLinks}>
            <p>Already have account?</p>
            <Link to="/sign-in" className="footer-link">
              Sign in
            </Link>
          </div>
          <div className="text--center">
            <LinkTo />
          </div>
          <Copyright />
        </div>
      </div>
    </Motion>
  )
}

export default SignUp
