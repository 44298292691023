import React from 'react'
import style from './LangSelect.module.css'
import LangButton from './LangButton'
import { useDispatch as useReduxDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from '../../store'
import { updateTargetLanguage, updateSourceLanguage } from '../../store'

function LangSelect() {
  const useReduxDispatchTyped = (): AppDispatch =>
    useReduxDispatch<AppDispatch>()
  const dispatch = useReduxDispatchTyped()
  const { sourceLanguage, targetLanguage } = useSelector(
    (state: RootState) => state.language
  )

  const handleSourceLanguageChange = (newLanguage: string) => {
    if (newLanguage === targetLanguage) {
      dispatch(
        updateTargetLanguage({
          targetLanguage: sourceLanguage,
        })
      )
    }
    dispatch(updateSourceLanguage({ sourceLanguage: newLanguage }))
  }

  const handleTargetLanguageChange = (newLanguage: string) => {
    if (newLanguage === sourceLanguage) {
      dispatch(
        updateSourceLanguage({
          sourceLanguage: targetLanguage,
        })
      )
    }
    dispatch(updateTargetLanguage({ targetLanguage: newLanguage }))
  }

  return (
    <div className={style.wrapper}>
      <LangButton
        currentLanguage={sourceLanguage}
        onLanguageChange={handleSourceLanguageChange}
      />
      <div className={style.and}>&</div>
      <LangButton
        currentLanguage={targetLanguage}
        onLanguageChange={handleTargetLanguageChange}
      />
    </div>
  )
}

export default LangSelect
