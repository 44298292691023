// src/editor/components/NoteBody.tsx
import React, { useCallback, useMemo, useEffect } from 'react'
import isHotkey from 'is-hotkey'
import { useEditorContext } from '../../contexts/EditorContext'
import {
  Editable,
  withReact,
  Slate,
  RenderElementProps,
  RenderLeafProps,
} from 'slate-react'
import { createEditor, Descendant } from 'slate'
import { withHistory } from 'slate-history'
import { animate } from 'framer-motion'
import {
  toggleMark,
  toggleBlock,
  removeFormatting,
} from '../../utilities/editorUtils'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Node, Text, Range } from 'slate'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { languages } from '../../utilities/languages'
import { Editor, Transforms } from 'slate'
import Element from '../../slate/components/Element'
import Leaf from '../../slate/components/Leaf'
import {
  detectTinyld,
  detectLanguage,
  detectFranc,
} from '../../utilities/detectLangua'

const HOTKEYS: { [key: string]: string } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
  'mod+alt+2': 'heading-two',
  'mod+alt+3': 'heading-three',
  'mod+shift+7': 'bulleted-list',
  'mod+shift+8': 'numbered-list',
  'mod+\\': 'remove-formatting',
  'mod+e': 'redo',
  'mod+z': 'undo',
}

interface NoteBodyProps {
  value?: Descendant[]
  onChange?: (value: Descendant[]) => void
}

// Debounce Function
const debounce = (func: Function, delay: number) => {
  let debounceTimer: NodeJS.Timeout
  return (...args: any[]) => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func(...args), delay)
  }
}

const NoteBody: React.FC<NoteBodyProps> = ({ value, onChange }) => {
  const { editor, setEditor } = useEditorContext()
  const localEditor = useMemo(() => withHistory(withReact(createEditor())), [])

  useEffect(() => {
    if (!editor) {
      setEditor(localEditor)
    }
  }, [editor, localEditor, setEditor])

  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  )
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  )

  const defaultValue: Descendant[] = [
    { type: 'paragraph', children: [{ text: '' }] } as Descendant,
  ]

  const updateScrollPosition = debounce(() => {
    const selection = window.getSelection()
    if (selection && selection.rangeCount > 0) {
      const cursorPosY = selection.getRangeAt(0).getBoundingClientRect().top
      const windowHeight = window.visualViewport
        ? window.visualViewport.height
        : document.querySelector('html')?.clientHeight

      if (cursorPosY && windowHeight) {
        const safeBottom = windowHeight * 0.6
        const targetPosY = windowHeight * 0.5
        const scrollPosY = window.scrollY

        if (cursorPosY > safeBottom) {
          const newScrollY = (targetPosY - cursorPosY) * -1 + window.scrollY
          animate(scrollPosY, newScrollY, {
            ease: 'easeOut',
            onUpdate: (scrollPosY) => {
              window.scrollTo(0, scrollPosY)
            },
          })
        }
      }
    }
  }, 50)

  const { sourceLanguage, targetLanguage } = useSelector(
    (state: RootState) => state.language
  )

  const detectLanguageOfWords = useCallback(
    debounce(async (text: string, lastWord: string, wordLocation: Range) => {
      interface LanguageArray {
        languages: { languageCode: string }[]
      }

      const isLanguageArray = (obj: any): obj is LanguageArray => {
        return (
          obj &&
          typeof obj === 'object' &&
          Array.isArray(obj.languages) &&
          obj.languages.every(
            (lang: { languageCode: string }) =>
              typeof lang.languageCode === 'string'
          )
        )
      }

      const functions = getFunctions()
      const detectGoogleWord = httpsCallable(functions, 'googleDetect')

      const words = text.split(' ').filter((word) => word.trim() !== '')
      Editor.removeMark(editor!, 'foreignLanguage')

      for (const word of words) {
        try {
          console.clear()
          console.log(`Current language: ${targetLanguage}`)
          console.log(
            'Current language code',
            languages[targetLanguage].googleCode
          )
          console.log('Front Detecting:', word)

          // Checking on the client using tinyld
          const clientResultTinyld = await detectTinyld({ data: word })
          console.log('Tinyld result:', clientResultTinyld.language)

          // Check on the client using languagedetect
          let longText: string
          if (word.length < 3) {
            longText = word + ' ' + word + ' ' + word + ' ' + word + ' ' + word
            console.log('Long text:', longText)
          } else {
            longText = word
          }
          const clientResultFranc = detectFranc(word, sourceLanguage)
          console.log('Franc result:', clientResultFranc)

          const clientResultLanguageDetect = detectLanguage(
            longText,
            sourceLanguage
          )
          console.log('LanguageDetect result:', clientResultLanguageDetect)

          let shouldCheckServer = false
          let shouldHighlight = false
          let shouldHighlightWarning = false
          let shouldHighlightNative = false

          if (
            clientResultTinyld.language ===
              languages[sourceLanguage].detectLang ||
            clientResultLanguageDetect ===
              languages[sourceLanguage].detectLang ||
            clientResultFranc === languages[sourceLanguage].detectFranc
          ) {
            shouldHighlight = true
            console.log('Highlighting:', word)
          } else {
            if (
              clientResultTinyld.language ===
                languages[targetLanguage].detectLang ||
              clientResultLanguageDetect ===
                languages[targetLanguage].detectLang ||
              clientResultFranc === languages[targetLanguage].detectFranc
            ) {
              shouldCheckServer = false
              shouldHighlightNative = true
            } else {
              shouldCheckServer = true
            }
          }

          if (shouldCheckServer) {
            const serverResult = await detectGoogleWord({ text: word })
            console.log('Back Detecting:', word)
            console.log('Google Translate:', serverResult)

            if (isLanguageArray(serverResult.data)) {
              const detectedLanguage =
                serverResult.data.languages[0].languageCode
              const expectedLanguage = languages[targetLanguage].googleCode

              if (detectedLanguage === expectedLanguage) {
                shouldHighlightNative = true
                console.log('Highlighting native:', word)
              } else if (
                detectedLanguage === languages[sourceLanguage].googleCode
              ) {
                shouldHighlight = true
                console.log('Highlighting:', word)
              } else {
                shouldHighlight = true
                console.log('Highlighting warning:', word)
              }
            }
          }

          if (shouldHighlight) {
            Transforms.select(editor!, wordLocation)
            Editor.addMark(editor!, 'foreignLanguage', true)
            Transforms.collapse(editor!, { edge: 'end' })
            Transforms.move(editor!)
            Editor.removeMark(editor!, 'foreignLanguage')
          } else {
            Editor.removeMark(editor!, 'foreignLanguage')
          }
          if (shouldHighlightWarning) {
            if (editor!.selection) {
              const { anchor } = editor!.selection
              Transforms.select(editor!, {
                anchor: {
                  ...anchor,
                  offset: anchor.offset - lastWord.length - 1,
                },
                focus: editor!.selection.focus,
              })
              Editor.addMark(editor!, 'warningLanguage', true)
              Transforms.collapse(editor!, { edge: 'end' })
              Transforms.move(editor!)
              Editor.removeMark(editor!, 'warningLanguage')
            }
          }
          if (shouldHighlightNative) {
            if (editor!.selection) {
              const { anchor } = editor!.selection
              Transforms.select(editor!, {
                anchor: {
                  ...anchor,
                  offset: anchor.offset - lastWord.length - 1,
                },
                focus: editor!.selection.focus,
              })
              Editor.addMark(editor!, 'nativeLanguage', true)
              Transforms.collapse(editor!, { edge: 'end' })
              Transforms.move(editor!)
              Editor.removeMark(editor!, 'nativeLanguage')
            }
          }
        } catch (error) {
          console.error('Error detecting language of word:', error)
        }
      }
    }, 500),
    [editor, targetLanguage, sourceLanguage]
  )

  const isPunctuationOrSpace = (char: string) => {
    const punctuationRegex = /[\s.,;:!?"'`~(){}[\]\\/<>@#$%^&*+=-]/
    return punctuationRegex.test(char)
  }

  const handleKeyUp = useMemo(
    () =>
      debounce(async (event: React.KeyboardEvent) => {
        if (!editor) return console.log('handleKeyUp: editor is null')
        console.log('handleKeyUp called')
        const textNodes = Array.from(Node.descendants(editor)).filter(
          ([node]) => Text.isText(node)
        )

        const textContent = textNodes
          .map(([node]) => (node as Text).text)
          .join(' ')

        console.log('textContent:', textContent)

        const words = textContent.trim().split(' ')
        const lastWord = words[words.length - 1]

        console.log('lastWord:', lastWord)

        if (isPunctuationOrSpace(event.key) || event.key === ' ') {
          for (const [node, path] of textNodes) {
            const text = (node as Text).text
            const wordIndex = text.lastIndexOf(lastWord)
            if (wordIndex !== -1) {
              const wordRange: Range = {
                anchor: { path, offset: wordIndex },
                focus: { path, offset: wordIndex + lastWord.length },
              }
              console.log('wordRange', wordRange)
              try {
                detectLanguageOfWords(lastWord, lastWord, wordRange)
              } catch (error) {
                console.error('Error handling language detection:', error)
              }
            }
          }
        }
      }, 200),
    [editor, detectLanguageOfWords]
  )

  return (
    <Slate
      editor={localEditor}
      initialValue={value || defaultValue}
      onChange={onChange}
    >
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Write here..."
        autoFocus
        onKeyUp={handleKeyUp}
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event as any)) {
              event.preventDefault()
              const mark = HOTKEYS[hotkey]

              if (mark === 'heading-two') {
                toggleBlock(localEditor, 'heading-two')
              } else if (mark === 'heading-three') {
                toggleBlock(localEditor, 'heading-three')
              } else if (mark === 'bulleted-list') {
                toggleBlock(localEditor, 'bulleted-list')
              } else if (mark === 'numbered-list') {
                toggleBlock(localEditor, 'numbered-list')
              } else if (mark === 'remove-formatting') {
                removeFormatting(localEditor)
              } else if (mark === 'redo') {
                localEditor.redo()
              } else if (mark === 'undo') {
                localEditor.undo()
              } else {
                toggleMark(localEditor, mark)
              }
            }
          }

          if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
            if (
              !(
                event.metaKey ||
                event.ctrlKey ||
                event.altKey ||
                event.shiftKey
              )
            ) {
              updateScrollPosition()
            }
          }
        }}
      />
    </Slate>
  )
}

export default NoteBody
