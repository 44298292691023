import React, { ReactNode, ForwardRefRenderFunction } from 'react'
import styles from './MenuItem.module.css'

interface MenuItemProps {
  children: ReactNode
  isActive?: boolean
  onClick: () => void
}

const MenuItem: ForwardRefRenderFunction<HTMLButtonElement, MenuItemProps> = (
  { children, onClick, isActive = false },
  ref
) => {
  const classNames = isActive ? `${styles.menuItemActive}` : styles.menuItem
  return (
    <button className={classNames} onClick={onClick} ref={ref}>
      {children}
    </button>
  )
}

export default React.forwardRef(MenuItem)
