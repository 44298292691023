import React, { useState, useEffect } from 'react'

// Создание контекста для управления размером шрифта
export const FontSizeContext = React.createContext({
  fontSize: 'medium',
  setFontSize: (value: string) => {},
})

// Создание провайдера, который будет обертывать ваше приложение
export const FontSizeProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  // Получение сохраненного размера шрифта из localStorage
  const storedFontSize = localStorage.getItem('font-size')

  // Если значение отсутствует в localStorage, установить значение по умолчанию и сохранить его в localStorage
  if (!storedFontSize) {
    localStorage.setItem('font-size', 'medium')
  }

  // Использование состояния для управления текущим размером шрифта
  const [fontSize, setFontSize] = useState<string>(
    localStorage.getItem('font-size') || 'medium'
  )

  // Отслеживание изменений размера шрифта и сохранение их в localStorage
  useEffect(() => {
    localStorage.setItem('font-size', fontSize)
  }, [fontSize])

  return (
    <FontSizeContext.Provider value={{ fontSize, setFontSize }}>
      {children}
    </FontSizeContext.Provider>
  )
}
