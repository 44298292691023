import { ReactNode, ReactEventHandler, useRef, SyntheticEvent } from 'react'

import styles from './Input.module.css'

const Input = (props: {
  autoComplete?: string
  isRequired?: boolean
  label?: ReactNode
  minLength?: number
  onChange?: (value: string, event: SyntheticEvent) => void
  onFocus?: () => void
  onBlur?: () => void
  placeholder?: string
  type?: 'text' | 'email' | 'password'
  value?: string
  autofocus?: boolean
  size?: 's' | 'm'
}) => {
  const {
    autoComplete,
    isRequired,
    label,
    minLength,
    onChange,
    placeholder,
    type,
    value,
    autofocus,
    size = 'm',
  } = props
  const inputEle = useRef<HTMLInputElement>(null)

  const handleChange: ReactEventHandler<HTMLInputElement> = (event) => {
    if (typeof onChange === 'function' && inputEle.current) {
      onChange(inputEle.current.value, event)
    }
  }
  const inputClass = size === 's' ? styles.inputS : styles.input
  return (
    <label className={styles.wrapper}>
      <div className={styles.label}>{label}</div>
      <input
        autoComplete={autoComplete}
        className={inputClass}
        minLength={minLength}
        onChange={handleChange}
        placeholder={placeholder}
        ref={inputEle}
        required={isRequired}
        type={type}
        value={value}
        autoFocus={autofocus}
      />
    </label>
  )
}

Input.defaultProps = {
  autoComplete: 'on',
  type: 'text',
}

export default Input
