import * as React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styles from './Upgrade.module.css'
import Button from '../ui-kit/Button'

import { CardIcon, OkIcon, CloseIcon } from '../assets'

interface UpgradeProps {
  onSelect?: () => void
  onOpenChange?: (open: boolean) => void
  isDropdownMenu?: boolean
  children?: React.ReactNode
}

const Upgrade: React.FC<UpgradeProps> = ({
  onSelect,
  onOpenChange,
  isDropdownMenu,
  children,
}) => {
  const [buttonRefs, setButtonRefs] = React.useState<
    React.RefObject<HTMLButtonElement>[]
  >([])

  React.useEffect(() => {
    setButtonRefs([
      React.createRef<HTMLButtonElement>(),
      React.createRef<HTMLButtonElement>(),
      React.createRef<HTMLButtonElement>(),
    ])
  }, [])

  const [activeButtonIndex, setActiveButtonIndex] = React.useState(0)

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown':
        setActiveButtonIndex((prevIndex) => (prevIndex + 1) % buttonRefs.length)
        break
      case 'ArrowUp':
        setActiveButtonIndex(
          (prevIndex) => (prevIndex - 1 + buttonRefs.length) % buttonRefs.length
        )
        break
      default:
        break
    }
  }

  React.useEffect(() => {
    const currentButtonRef = buttonRefs[activeButtonIndex]?.current
    if (currentButtonRef) {
      currentButtonRef.focus()
    }
  }, [activeButtonIndex, buttonRefs])

  return (
    <Dialog.Root
      onOpenChange={(open) => {
        if (onOpenChange) onOpenChange(open)
      }}
    >
      {isDropdownMenu ? (
        <Dialog.Trigger asChild>
          <DropdownMenu.Item
            className={styles.item}
            onSelect={(event) => {
              event.preventDefault()
              if (onSelect) onSelect()
            }}
          >
            <CardIcon className={styles.icon} />
            Upgrade plan
          </DropdownMenu.Item>
        </Dialog.Trigger>
      ) : (
        <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      )}

      <Dialog.Portal>
        <Dialog.Overlay className={styles.DialogOverlay} />
        <Dialog.Content className={styles.DialogContent}>
          <Dialog.Title className={styles.DialogTitle}>
            <div>
              Upgrade to Diglot <span className={styles.proPlan}>Pro</span>
            </div>
            <div className={styles.closeButton}>
              <Dialog.Close asChild>
                <button className={styles.IconButton} aria-label="Close">
                  <CloseIcon className={styles.icon} />
                </button>
              </Dialog.Close>
            </div>
          </Dialog.Title>
          <div className={styles.content}>
            <div className={styles.contentWrapper}>
              <Dialog.Description className={styles.DialogDescription}>
                Unlock the Full Spectrum of Your Writing Brilliance!
              </Dialog.Description>
              <div className={styles.featureRow}>
                <OkIcon className={styles.iconBulet} />

                <div className={styles.feature}>
                  <span className={styles.featureTitle}>
                    AI Writing Assistance
                  </span>
                  <span>Sharpen your prose with smart guidance.</span>
                </div>
              </div>

              <div className={styles.featureRow}>
                <OkIcon className={styles.iconBulet} />

                <div className={styles.feature}>
                  <span className={styles.featureTitle}>
                    Built-in & Double Translation
                  </span>
                  <span>Write boldly; we'll catch the errors.</span>
                </div>
              </div>

              <div className={styles.featureRow}>
                <OkIcon className={styles.iconBulet} />
                <div className={styles.feature}>
                  <span className={styles.featureTitle}>
                    Grammar & Spelling Checker
                  </span>
                  <span>Spotless writing for a professional look.</span>
                </div>
              </div>

              <div className={styles.featureRow}>
                <OkIcon className={styles.iconBulet} />
                <div className={styles.feature}>
                  <span className={styles.featureTitle}>
                    Parahpasing & Synonyms Tool
                  </span>
                  <span>Unlimited creativity, endless expression.</span>
                </div>
              </div>

              <div className={styles.featureRow}>
                <OkIcon className={styles.iconBulet} />
                <div className={styles.feature}>
                  <span className={styles.featureTitle}>
                    100k Words Translation
                  </span>
                  <span>Overcome language barriers</span>
                </div>
              </div>

              <div className={styles.buttonWrapper}>
                <Button
                  className={styles.buttonTop}
                  ref={buttonRefs[0]}
                  tabIndex={0}
                  onKeyDown={handleKeyDown}
                >
                  <div className={styles.leftBlock}>
                    <div className={styles.titleWrapper}>
                      <div className={styles.planTitle}>Annual</div>{' '}
                      <div className={styles.bage}>Most popular</div>
                    </div>

                    <div className={styles.planDescription}>
                      Billed as one payment of €144.00
                    </div>
                  </div>
                  <div className={styles.rightBlock}>
                    <div className={styles.priceWrapper}>
                      <div className={styles.price}>$12</div>
                      <div className={styles.month}>/ month</div>
                    </div>
                    <div className={styles.save}>Save 60%</div>
                  </div>
                </Button>
                <Button
                  className={styles.buttonMiddle}
                  ref={buttonRefs[1]}
                  tabIndex={0}
                  onKeyDown={handleKeyDown}
                >
                  <div className={styles.leftBlock}>
                    <div className={styles.planTitle}> Quarterly</div>
                    <div className={styles.planDescription}>
                      Billed as one payment of €60.00
                    </div>
                  </div>
                  <div className={styles.rightBlock}>
                    <div className={styles.priceWrapper}>
                      <div className={styles.price}>$20</div>
                      <div className={styles.month}>/ month</div>
                    </div>
                    <div className={styles.save}>Save 33%</div>
                  </div>
                </Button>
                <Button
                  className={styles.buttonBottom}
                  ref={buttonRefs[2]}
                  tabIndex={0}
                  onKeyDown={handleKeyDown}
                >
                  <div className={styles.leftBlock}>
                    <div className={styles.planTitle}>Monthly</div>
                  </div>
                  <div className={styles.rightBlock}>
                    <div className={styles.priceWrapper}>
                      <div className={styles.price}>$30</div>
                      <div className={styles.month}>/ month</div>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
            <div className={styles.upgradeImage}></div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default Upgrade
