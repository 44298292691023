import { ChangeEvent, KeyboardEventHandler } from 'react'
import React from 'react'
import styles from './NoteTitle.module.css'
import TextareaAutosize from 'react-textarea-autosize'
import { FontSizeContext } from '../../contexts/FontSizeContext'

const NoteTitle = (props: {
  onChange: (value: string) => any
  placeholder: string
  value: string
}) => {
  const { onChange, placeholder, value } = props

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value || '')
  }

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter') event.preventDefault()
  }

  const { fontSize: fontSizeValue } = React.useContext(FontSizeContext) // Получите значение fontSize из контекста

  const h1Styles = {
    fontSize:
      fontSizeValue === 'small'
        ? '28px'
        : fontSizeValue === 'medium'
        ? '32px'
        : '40px',
    lineHeight:
      fontSizeValue === 'small'
        ? '1.4'
        : fontSizeValue === 'medium'
        ? '1.3'
        : '1.2', // Устанавливаем высоту строки в соответствии с размером шрифта
  }

  return (
    <div className={styles.wrapper}>
      <TextareaAutosize
        className={`h1 ${styles.textarea}`}
        style={h1Styles}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
      />
    </div>
  )
}

export default NoteTitle
