import React from 'react'
import styles from './CopyAllText.module.css'
import * as Toast from '@radix-ui/react-toast'
import * as Tooltip from '@radix-ui/react-tooltip'
import { CopyIcon } from '../../assets'
import IconButton from '../../ui-kit/IconButton'

import { Node, Editor } from 'slate'
import { useEditorContext } from '../../contexts/EditorContext'

function CopyAllText() {
  const [open, setOpen] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  const { editor } = useEditorContext()

  const serializeWithNewLines = (editor: Editor) => {
    let result = ''
    for (const [node] of Node.nodes(editor)) {
      if ('type' in node) {
        const text = Node.string(node)
        switch (node.type) {
          case 'paragraph':
            result += text + '\n'
            break
          case 'h2':
            result += `## ${text}\n` // Дополнительный перенос строки
            break
          case 'h3':
            result += `### ${text}\n` // Дополнительный перенос строки
            break

          default:
            result += text + '\n'
            break
        }
      }
    }
    return result
  }

  const handleCopyClick = () => {
    if (editor) {
      const allText = serializeWithNewLines(editor)
      navigator.clipboard
        .writeText(allText)
        .then(() => {
          console.log('Text successfully copied to clipboard')
          setSuccess(true) // Успешное копирование
          setOpen(true) // Открыть уведомление
        })
        .catch((err) => {
          console.log('Failed to copy text: ', err)
          setSuccess(false) // Копирование не удалось
          setOpen(true) // Открыть уведомление
        })
    }
  }

  return (
    <Toast.Provider swipeDirection="right">
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <span>
              <IconButton onClick={handleCopyClick}>
                <CopyIcon />
              </IconButton>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
              Copy all text
              <Tooltip.Arrow className={styles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      <Toast.Root
        className={styles.ToastRoot}
        open={open}
        onOpenChange={setOpen}
      >
        <Toast.Title className={styles.ToastTitle}>
          {success ? 'All text successfully copied!' : 'Failed to copy text.'}
        </Toast.Title>
        <Toast.Description>
          {success
            ? 'Use command + V to paste it'
            : 'An error occurred while copying.'}
        </Toast.Description>
        <Toast.Action className={styles.ToastAction} asChild altText="Ok">
          <button className="Button small green" onClick={() => setOpen(false)}>
            Ok
          </button>
        </Toast.Action>
      </Toast.Root>

      <Toast.Viewport className={styles.ToastViewport} />
    </Toast.Provider>
  )
}

export default CopyAllText
