import React from 'react'
import styles from './NoteLayout.module.css'
import { useModulesContext } from '../../contexts/ModulesContext'

interface NoteLayoutProps {
  children: React.ReactNode
}

const NoteLayout: React.FC<NoteLayoutProps> = ({ children }) => {
  const {
    isTranslateWordOpen,
    isTranslateTextOpen,
    isCowriterOpen,
    isGrammarOpen,
    isPlagiarismOpen,
    isParaphraseOpen,
    isTemplatesOpen,
  } = useModulesContext()

  const isAnyModuleOpen =
    isTranslateWordOpen ||
    isTranslateTextOpen ||
    isCowriterOpen ||
    isGrammarOpen ||
    isPlagiarismOpen ||
    isParaphraseOpen ||
    isTemplatesOpen

  return (
    <div className={styles.alignCenter}>
      <div
        className={
          isAnyModuleOpen ? styles.editorScroll : styles.editorNoScroll
        }
      >
        {children}
      </div>
    </div>
  )
}

export default NoteLayout
