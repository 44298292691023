import React, { createContext, useContext, useState, useCallback } from 'react'
import { Editor, Transforms, Range } from 'slate'
import { ReactEditor } from 'slate-react'

interface EditorContextProps {
  editor: Editor | null
  setEditor: React.Dispatch<React.SetStateAction<Editor | null>>
  isItFirstTime: boolean
  setFirstTime: (isItFirstTime: boolean) => void
  updateText: (newValue: string) => void
  savedSelection: Range | null
  setSavedSelection: React.Dispatch<React.SetStateAction<Range | null>>
}

const EditorContext = createContext<EditorContextProps | null>(null)

export const useEditorContext = (): EditorContextProps => {
  const context = useContext(EditorContext)
  if (!context) {
    throw new Error('useEditorContext must be used within an EditorProvider')
  }
  return context
}

export const selectAllText = (editor: ReactEditor) => {
  const { children } = editor
  const firstNode = children[0]
  const lastNode = children[children.length - 1]

  if (firstNode && lastNode) {
    const firstPath = [0]
    const lastPath = [children.length - 1]
    const start = Editor.start(editor, firstPath)
    const end = Editor.end(editor, lastPath)

    if (start && end) {
      const newRange = { anchor: start, focus: end }
      Transforms.select(editor, newRange)
      ReactEditor.focus(editor)
    }
  }
}

interface EditorProviderProps {
  children: React.ReactNode
}

export const EditorProvider: React.FC<EditorProviderProps> = ({ children }) => {
  const [editor, setEditor] = useState<Editor | null>(null)
  const [savedSelection, setSavedSelection] = useState<Range | null>(null)
  const [isItFirstTime, setIsItFirstTime] = useState(true)

  const setFirstTime = useCallback((isItFirstTime: boolean) => {
    setIsItFirstTime(isItFirstTime)
  }, [])

  const updateText = useCallback(
    (newText: string) => {
      console.log('updateText in context', newText)
      console.log('savedSelection in context', savedSelection)

      // Если редактор существует, но не в фокусе, устанавливаем фокус
      if (editor && !ReactEditor.isFocused(editor as ReactEditor)) {
        ReactEditor.focus(editor as ReactEditor)
      }

      if (editor) {
        console.log('editor is focused or focus has been reset')
        if (savedSelection) {
          // 1. Установить сохраненный диапазон
          Transforms.select(editor, savedSelection)

          // 2. Удаляем текущий выбранный текст
          Transforms.delete(editor, { at: savedSelection })

          // 3. Преобразуем новый текст в нужный формат.
          const text = { text: newText }

          // 4. Вставляем новый текст в текущую позицию
          Transforms.insertNodes(editor, text)

          // 5. Сбросить сохраненный диапазон
          // Этот шаг необязательный, зависит от вашей логики
          setSavedSelection(null)
        }
      }
    },
    [editor, savedSelection]
  )

  return (
    <EditorContext.Provider
      value={{
        editor,
        setEditor,
        isItFirstTime,
        setFirstTime,
        updateText,
        savedSelection,
        setSavedSelection,
      }}
    >
      {children}
    </EditorContext.Provider>
  )
}
