import React from 'react'
import { SpinnerIcon } from '../assets'
import styles from './Spinner.module.css'

type Size = 's' | 'm' | 'l'
type Color = 'primary' | 'secondary'

interface SpinnerProps {
  size?: Size
  color?: Color
}

const Spinner: React.FC<SpinnerProps> = ({ size = 'm', color = 'primary' }) => {
  let wrapperSizeClass: string
  let iconSizeClass: string
  let wrapperColorClass: string

  switch (size) {
    case 's':
      wrapperSizeClass = styles.s
      iconSizeClass = styles.s
      break
    case 'm':
      wrapperSizeClass = styles.m
      iconSizeClass = styles.m
      break
    case 'l':
      wrapperSizeClass = styles.l
      iconSizeClass = styles.l
      break
  }

  switch (color) {
    case 'primary':
      wrapperColorClass = styles.primary
      break
    case 'secondary':
      wrapperColorClass = styles.secondary
      break
  }

  return (
    <div
      className={`${styles.wrapper} ${wrapperSizeClass} ${wrapperColorClass}`}
    >
      <SpinnerIcon
        className={`${styles.icon} ${iconSizeClass}`}
        title="Loading..."
        aria-label="Loading..."
      />
    </div>
  )
}

export default Spinner
