import React from 'react'
import styles from './TranslateRow.module.css'

interface TranslateRowProps {
  word: string
  translation: string
}

const TranslationRow: React.FC<TranslateRowProps> = ({ word, translation }) => {
  return (
    <button className={styles.wrapper}>
      <div className={styles.word}>{word}</div>
      <div className={styles.translation}>{translation}</div>
    </button>
  )
}

export default TranslationRow
