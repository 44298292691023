// src/slate/components/Element.tsx
import React, { ReactNode, HTMLAttributes, FC } from 'react'
import { CustomElement } from '../../utilities/editorUtils' // Предполагая, что путь верный
// import style from '../editor/NoteBody.module.css'
import { Properties } from 'csstype'

interface ElementProps {
  attributes: HTMLAttributes<HTMLElement>
  children: ReactNode
  element: CustomElement
}

const Element: FC<ElementProps> = ({ attributes, children, element }) => {
  const style: Properties<string | number, string & {}> = {
    textAlign: element.align as any,
  }

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'heading-three':
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

export default Element
