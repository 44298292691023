import React, { useEffect } from 'react'
import styles from './Editor.module.css'
import EditorLayout from './components/EditorLayout'
import NoteLayout from './components/NoteLayout'
import OptionLayout from './components/OptionLayout'
import NoteTitle from './components/NoteTitle'
import NoteBody from './components/NoteBody'
import Toolbar from '../toolbar/Toolbar'
import TranslateWord from './components/TranslateWord'
import Cowriter from '../modules/cowriter/Cowriter'
import TranslateText from './components/TranslateText'
import TranslatePlaceholder from './components/TranslatePlaceholder'
import Grammar from '../modules/grammar/Grammar'
import Plagiarism from '../modules/plagiarism/Plagiarism'
import Paraphrase from '../modules/paraphrase/Paraphrase'
import Templates from '../modules/templates/Templates'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import { convertDateToString } from '../utilities/helpers'
import { Descendant } from 'slate'
import { useEditorContext } from '../contexts/EditorContext'
import { useModulesContext } from '../contexts/ModulesContext'
import {
  appDispatch,
  NoteLike,
  postNote,
  selectCurrentNote,
  selectIsSaving,
  setIsSaving,
  store,
  updateNote,
} from '../store'

const handleNoteUpdate = (
  value: { title?: string; body?: Descendant[] },
  note: NoteLike
) => {
  const newNote = { ...note }

  if (typeof value.title == 'string') newNote.title = value.title
  if (value.body) newNote.body = JSON.stringify(value.body)

  newNote.lastModifiedDate = convertDateToString(new Date())

  appDispatch(updateNote(newNote))
}

const delayedPostNote = debounce((note: NoteLike) => {
  if (note) {
    appDispatch(postNote(note))
  }
}, 1000)

const Editor: React.FC = () => {
  const { isItFirstTime, setFirstTime } = useEditorContext()

  const {
    isTranslateTextOpen,
    isCowriterOpen,
    isTranslateWordOpen,
    isGrammarOpen,
    isPlagiarismOpen,
    isParaphraseOpen,
    isTemplatesOpen,
    closeTranslateText,
    openTranslateWord,
    closeCowriter,
    openCowriter,
    translateText,
    translateWord,
  } = useModulesContext()

  const note = useSelector(selectCurrentNote)

  useEffect(() => {
    if (note) {
      !selectIsSaving(store.getState()) && appDispatch(setIsSaving(true))
      delayedPostNote(note)
    }
  }, [note])

  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      if (selectIsSaving(store.getState())) {
        event.preventDefault()
        return (event.returnValue = 'Changes to your note may not be saved.')
      }
    }

    window.addEventListener('beforeunload', unloadHandler)

    return () => {
      window.removeEventListener('beforeunload', unloadHandler)
      delayedPostNote.flush()
    }
  }, [])

  const toggleTranslateWord = () => {
    if (isTranslateTextOpen) {
      closeTranslateText()
    }
    openTranslateWord(translateWord)
    setFirstTime(false)
  }

  const toggleCowriter = () => {
    if (isCowriterOpen) {
      closeCowriter()
    }
    openCowriter()
    setFirstTime(false)
  }

  return (
    <>
      <Toolbar size={isItFirstTime ? 'slim' : 'wide'} />
      <EditorLayout>
        <NoteLayout>
          <NoteTitle
            onChange={(value) => {
              if (note) {
                handleNoteUpdate({ title: value }, note)
              }
            }}
            placeholder="Untitled Note"
            value={note?.title ?? 'Untitled Note'}
          />
          <button className={styles.temp} onClick={toggleTranslateWord}>
            Розумний
          </button>
          <button className={styles.temp} onClick={toggleCowriter}>
            Cowriter
          </button>
          <NoteBody
            key={note?.id}
            onChange={(value) => {
              if (note) {
                handleNoteUpdate({ body: value }, note)
              }
            }}
            value={note?.body ? JSON.parse(note.body) : null}
          />
        </NoteLayout>
        <OptionLayout>
          {isTranslateWordOpen && <TranslateWord word="Розумний" />}
          {isCowriterOpen && <Cowriter />}
          {isGrammarOpen && <Grammar />}
          {isPlagiarismOpen && <Plagiarism />}
          {isParaphraseOpen && <Paraphrase />}
          {isTemplatesOpen && <Templates />}
          {isTranslateTextOpen && <TranslateText initialText={translateText} />}
          <TranslatePlaceholder show={isItFirstTime} />
        </OptionLayout>
      </EditorLayout>
    </>
  )
}

export default Editor
