import React, {
  ReactNode,
  ReactEventHandler,
  forwardRef,
  MouseEventHandler,
} from 'react'
import styles from './IconButton.module.css'

type SizeLike = 's' | 'm' | 'l'

interface IconButtonProps {
  children: ReactNode
  isActive?: boolean
  onClick?: ReactEventHandler
  onMouseDown?: MouseEventHandler<HTMLButtonElement>
  size?: SizeLike
  primary?: boolean
  secondary?: boolean
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const {
      children,
      isActive,
      onClick,
      onMouseDown,
      size = 'm',
      primary,
      secondary,
    } = props
    let classNames = [styles.wrapper]
    if (isActive) classNames.push(styles.isActive)
    if (primary) classNames.push(styles.primary)
    if (secondary) classNames.push(styles.secondary)
    switch (size) {
      case 's':
        classNames.push(styles.buttonS)
        break
      case 'l':
        classNames.push(styles.buttonL)
        break
      default:
        classNames.push(styles.buttonM)
        break
    }

    return (
      <button
        tabIndex={0}
        className={classNames.join(' ')}
        onClick={onClick}
        onMouseDown={onMouseDown}
        ref={ref}
      >
        {children}
      </button>
    )
  }
)

export default IconButton
