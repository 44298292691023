import React from 'react'
import styles from './NoteStatus.module.css'
import { OkCircleIcon, CloseIcon } from '../../assets'
import IconButton from '../../ui-kit/IconButton'
import * as Popover from '@radix-ui/react-popover'
import TimeAgo from '../../ui-kit/TimeAgo'
import { useSelector } from 'react-redux'
import { selectCurrentNote } from '../../store'

function NoteStatus() {
  const note = useSelector(selectCurrentNote)

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <IconButton>
          <OkCircleIcon className={styles.icon2} />
        </IconButton>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={styles.PopoverContent}
          sideOffset={5}
          align="center"
        >
          <div className={styles.Title}>Note Status </div>
          <div className={styles.textWrapper}>
            <div className={styles.Text}>Last edit: </div>
            {note && (
              <div className={styles.Text2}>
                <TimeAgo date={note.lastModifiedDate} />
              </div>
            )}
          </div>
          <div className={styles.textWrapper}>
            <div className={styles.Text}>Created: </div>
            {note && (
              <div className={styles.Text2}>
                <TimeAgo date={note.createdDate} />
              </div>
            )}
          </div>
          <Popover.Close className={styles.PopoverClose} aria-label="Close">
            <CloseIcon className={styles.icon} />
          </Popover.Close>
          <Popover.Arrow className={styles.PopoverArrow} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default NoteStatus
