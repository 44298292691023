import React from 'react'
import { ReactComponent as DiglotLogo } from '../assets/diglot-logo.svg'

interface LogoProps {
  className?: string
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <div className={className}>
      <DiglotLogo />
    </div>
  )
}

export default Logo
