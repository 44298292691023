import './App.css'
import AppRoutes from './AppRouts'
import { BrowserRouter } from 'react-router-dom'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import Smartlook from 'smartlook-client'
import ReactPixel from 'react-facebook-pixel'

function App() {
  useEffect(() => {
    const theme = localStorage.getItem('color-scheme')
    const doc = document.firstElementChild

    if (theme === null) {
      localStorage.setItem('color-scheme', 'auto')
    }

    if (theme && doc) {
      switch (theme) {
        case 'auto':
          doc.setAttribute('color-scheme', 'auto')
          break
        case 'dark':
          doc.setAttribute('color-scheme', 'dark')
          break
        case 'light':
          doc.setAttribute('color-scheme', 'light')
          break
        default:
          break
      }
    }
  }, [])

  ReactPixel.init('707035084632757')
  ReactPixel.pageView() // For tracking page view

  ReactGA.initialize('G-RRN2ZEKCZK')
  Smartlook.init('b73fe2ee05eb5cfb70c36788c02349d640c7732f')

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
}

export default App
