import React from 'react'
import styles from './AppLayout.module.css'
import Sidebar from '../sidebar/Sidebar'
import Editor from '../editor/Editor'
import { FontSizeProvider } from '../contexts/FontSizeContext'
import { EditorProvider } from '../contexts/EditorContext'
import { ModulesProvider } from '../contexts/ModulesContext'

const AppLayout = () => {
  return (
    <div className={styles.app}>
      <Sidebar />
      <section className={styles.section}>
        <FontSizeProvider>
          <ModulesProvider>
            <EditorProvider>
              <Editor />
            </EditorProvider>
          </ModulesProvider>
        </FontSizeProvider>
      </section>
    </div>
  )
}

export default AppLayout
