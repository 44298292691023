import React from 'react'
import styles from './EditorToolbar.module.css'
import Divider from '../../ui-kit/Divider'
import IconButton from '../../ui-kit/IconButton'
import * as Tooltip from '@radix-ui/react-tooltip'
import { useEditorContext } from '../../contexts/EditorContext'
import { Editor } from 'slate'
import {
  BoldIcon,
  H2Icon,
  H3Icon,
  ItalicIcon,
  // LinkIcon,
  ListNumberIcon,
  ListPointIcon,
  UnderlineIcon,
  UnformatIcon,
} from '../../assets'

import {
  toggleMark,
  isMarkActive,
  toggleBlock,
  isBlockActive,
  removeFormatting,
  TEXT_ALIGN_TYPES,
} from '../../utilities/editorUtils'

function EditorToolbar() {
  const { editor } = useEditorContext()

  if (!editor) {
    return <div></div>
  }

  return (
    <div className={styles.pageMenuEditor}>
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <span>
              <BlockButton format="heading-two" editor={editor}>
                <H2Icon />
              </BlockButton>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
              Headline 2<div className={styles.RightSlot}>⌘+⌥+2</div>
              <Tooltip.Arrow className={styles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <span>
              <BlockButton format="heading-three" editor={editor}>
                <H3Icon />
              </BlockButton>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
              Headline 3 <div className={styles.RightSlot}>⌘+⌥+3</div>
              <Tooltip.Arrow className={styles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      <div className={styles.dividerPad}>
        <Divider direction="V" />
      </div>

      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <span>
              <MarkButton format="bold" editor={editor}>
                <BoldIcon />
              </MarkButton>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
              Bold <div className={styles.RightSlot}>⌘+B</div>
              <Tooltip.Arrow className={styles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <span>
              <MarkButton format="italic" editor={editor}>
                <ItalicIcon />
              </MarkButton>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
              Italic <div className={styles.RightSlot}>⌘+I</div>
              <Tooltip.Arrow className={styles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <span>
              <MarkButton format="underline" editor={editor}>
                <UnderlineIcon />
              </MarkButton>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
              Underline <div className={styles.RightSlot}>⌘+U</div>
              <Tooltip.Arrow className={styles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      <div className={styles.dividerPad}>
        <Divider direction="V" />
      </div>

      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <span>
              <BlockButton format="bulleted-list" editor={editor}>
                <ListPointIcon />
              </BlockButton>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
              Doted list <div className={styles.RightSlot}>⇧+⌘+7</div>
              <Tooltip.Arrow className={styles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <span>
              <BlockButton format="numbered-list" editor={editor}>
                <ListNumberIcon />
              </BlockButton>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
              Number list <div className={styles.RightSlot}>⇧+⌘+8</div>
              <Tooltip.Arrow className={styles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      {/* <div className={styles.dividerPad}>
        <Divider direction="V" />
      </div>

      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <span>
              <IconButton onClick={() => removeFormatting(editor)}>
                <LinkIcon />
              </IconButton>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
              Link
              <Tooltip.Arrow className={styles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider> */}

      <div className={styles.dividerPad}>
        <Divider direction="V" />
      </div>

      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <span>
              <IconButton onClick={() => removeFormatting(editor)}>
                <UnformatIcon />
              </IconButton>
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.TooltipContent} sideOffset={5}>
              Clear formating <div className={styles.RightSlot}>⌘+\</div>
              <Tooltip.Arrow className={styles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </div>
  )
}

interface ButtonProps {
  format: string
  children: React.ReactNode
  editor: Editor
}

const BlockButton: React.FC<ButtonProps> = ({ format, children, editor }) => {
  return (
    <IconButton
      isActive={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {children}
    </IconButton>
  )
}

const MarkButton: React.FC<ButtonProps> = ({ format, children, editor }) => {
  return (
    <IconButton
      isActive={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {children}
    </IconButton>
  )
}

export default EditorToolbar
