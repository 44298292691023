import React from 'react'
import style from './DeletedNote.module.css'

type DeletedNoteProps = {
  active?: boolean
  title: string
  body: string
}

function DeletedNote({ active = false, title, body }: DeletedNoteProps) {
  return (
    <button className={active ? style.deletedNoteActive : style.deletedNote}>
      <div className={style.deletedNoteTitle}>{title}</div>
      <div className={style.deletedNoteBody}>{body}</div>
    </button>
  )
}

export default DeletedNote
