import React, { useEffect, useState } from 'react'
import styles from './SidebarMenu.module.css'
import MenuItem from '../../ui-kit/MenuItem'
import { useModulesContext } from '../../contexts/ModulesContext'
import { useEditorContext } from '../../contexts/EditorContext'

type MenuItemType =
  | 'cowri'
  | 'trans'
  | 'gramm'
  | 'para'
  | 'plag'
  | 'templ'
  | null

const SidebarMenu: React.FC = () => {
  const {
    openCowriter,
    openTranslateWord,
    openGrammar,
    openPlagiarism,
    openParaphrase,
    openTemplates,
    closeAllModules,
    setOnClose,
    isTranslateWordOpen,
    isCowriterOpen,
    isGrammarOpen,
    isPlagiarismOpen,
    isParaphraseOpen,
    isTemplatesOpen,
  } = useModulesContext()

  const { setFirstTime } = useEditorContext()
  const [activeItem, setActiveItem] = useState<MenuItemType>(null)

  useEffect(() => {
    setOnClose(() => () => setActiveItem(null))
  }, [setOnClose])

  useEffect(() => {
    if (isTranslateWordOpen) setActiveItem('trans')
    else if (isCowriterOpen) setActiveItem('cowri')
    else if (isGrammarOpen) setActiveItem('gramm')
    else if (isPlagiarismOpen) setActiveItem('plag')
    else if (isParaphraseOpen) setActiveItem('para')
    else if (isTemplatesOpen) setActiveItem('templ')
  }, [
    isTranslateWordOpen,
    isCowriterOpen,
    isGrammarOpen,
    isPlagiarismOpen,
    isParaphraseOpen,
    isTemplatesOpen,
  ])

  const handleMenuItemClick = (type: MenuItemType) => {
    setFirstTime(false)
    setActiveItem(type)
    closeAllModules()

    switch (type) {
      case 'trans':
        openTranslateWord('example word')
        break
      case 'cowri':
        openCowriter()
        break
      case 'gramm':
        openGrammar()
        break
      case 'plag':
        openPlagiarism()
        break
      case 'para':
        openParaphrase()
        break
      case 'templ':
        openTemplates()
        break
      default:
        break
    }
  }

  return (
    <div className={styles.menu}>
      <MenuItem
        active={activeItem === 'cowri'}
        type="cowri"
        onClick={() => handleMenuItemClick('cowri')}
      />
      <MenuItem
        active={activeItem === 'trans'}
        type="trans"
        onClick={() => handleMenuItemClick('trans')}
      />
      <MenuItem
        active={activeItem === 'gramm'}
        type="gramm"
        onClick={() => handleMenuItemClick('gramm')}
      />
      <MenuItem
        active={activeItem === 'para'}
        type="para"
        onClick={() => handleMenuItemClick('para')}
      />
      <MenuItem
        active={activeItem === 'plag'}
        type="plag"
        onClick={() => handleMenuItemClick('plag')}
      />
      <MenuItem
        active={activeItem === 'templ'}
        type="templ"
        onClick={() => handleMenuItemClick('templ')}
      />
    </div>
  )
}

export default SidebarMenu
