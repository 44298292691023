import styles from './Translate.module.css'
import IconButton from '../../ui-kit/IconButton'
import { CloseIcon, ReloadIcon } from '../../assets'
import TranslateRow from './TranslateRow'
import TextareaAutosize from 'react-textarea-autosize'
import * as Tooltip from '@radix-ui/react-tooltip'
import { useModulesContext } from '../../contexts/ModulesContext'

interface TranslateWordProps {
  show?: boolean
  word: string
}

const TranslateWord: React.FC<TranslateWordProps> = ({
  show = false,
  word,
}) => {
  const { isTranslateWordOpen, closeTranslateWord } = useModulesContext()

  show = isTranslateWordOpen

  const translateWordClassName = show
    ? styles.options
    : `${styles.options} ${styles.hidden}`

  return (
    <div className={translateWordClassName}>
      <div className={styles.top}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div className={styles.title}>Українська:</div>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <IconButton size="s" onClick={closeTranslateWord}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    className={styles.TooltipContent}
                    sideOffset={5}
                  >
                    Close
                    <Tooltip.Arrow className={styles.TooltipArrow} />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
          <TextareaAutosize
            className={`${styles.textAria} ${styles.firstText}`}
            defaultValue={word}
            maxRows={10}
            placeholder="Enter word"
          />
        </div>
        <div className={`${styles.wrapper} ${styles.paddingBottom}`}>
          <div className={styles.header}>
            <div className={styles.title}>English:</div>
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <IconButton size="s">
                    <ReloadIcon />
                  </IconButton>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content
                    className={styles.TooltipContent}
                    sideOffset={5}
                  >
                    Reload
                    <Tooltip.Arrow className={styles.TooltipArrow} />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <TranslateRow
          word="reasonable"
          translation="небезпідставний, обгрунтований, прийнятий, резонний, розважливий, розумний"
        />
        <TranslateRow
          word="intelligent"
          translation="знаючий, нформаційний, кмітливий, розвинений, розумний"
        />
        <TranslateRow
          word="clever"
          translation="вправний, здібний, зручний, кмітливий,  красивий, розумний"
        />
        <TranslateRow
          word="wise"
          translation="глибокодумний, обачний, знахарський, поміркований, знаючий, розумний"
        />
        <TranslateRow
          word="rational"
          translation="нормальний, раціональний, розумний, розумовий"
        />
        <TranslateRow
          word="sensible"
          translation="відчутний, путящий, розумний, який усвідомлює, чутливий"
        />
        <TranslateRow word="understanding" translation="розумний, чуйний" />
        <TranslateRow word="brainy" translation="дотепний, розумний" />
        <TranslateRow
          word="tenable"
          translation="що закріплений, обороноздатний, розумний"
        />
        <TranslateRow
          word="advisable"
          translation="доцільний, рекомендований, розсудливий, розумний"
        />
        <TranslateRow
          word="witty"
          translation="дотепний, метикуватий, підступний, розумний"
        />
      </div>
    </div>
  )
}

export default TranslateWord
