import * as React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { CommandIcon, CloseIcon } from '../assets'
import './Hotkeys.module.css'
import styles from './Hotkeys.module.css'
import Divider from '../ui-kit/Divider'

interface HotkeysProps {
  onSelect: () => void
  onOpenChange: (open: boolean) => void
}

const Hotkeys: React.FC<HotkeysProps> = ({ onSelect, onOpenChange }) => {
  return (
    <Dialog.Root onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        <DropdownMenu.Item
          className={styles.item}
          onSelect={(event) => {
            event.preventDefault()
            onSelect()
          }}
        >
          <CommandIcon className={styles.icon} />
          Hot Keys
        </DropdownMenu.Item>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.DialogOverlay} />
        <Dialog.Content className={styles.DialogContent}>
          <Dialog.Title className={styles.DialogTitle}>
            Hot Keys
            <Dialog.Close asChild>
              <button className={styles.IconButton} aria-label="Close">
                <CloseIcon className={styles.icon} />
              </button>
            </Dialog.Close>
          </Dialog.Title>
          <Divider direction="H" />
          <div className={styles.content}>
            <div className={styles.title}>General</div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+N</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.dividerPad}>
              <Divider direction="H" />
            </div>
            <div className={styles.title}>Editor</div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+N</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.dividerPad}>
              <Divider direction="H" />
            </div>
            <div className={styles.title}>General</div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+N</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.dividerPad}>
              <Divider direction="H" />
            </div>
            <div className={styles.title}>Editor</div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+N</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
            <div className={styles.hotkeyRow}>
              <div className={styles.hotkeyName}>New note</div>
              <div className={styles.hotkeyValue}>⇧+⌘+Q</div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default Hotkeys
