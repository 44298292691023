import React, { ReactNode, ReactEventHandler, ForwardedRef } from 'react'
import styles from './Button.module.css'

type SizeLike = 's' | 'm'
type KindLike = 'danger' | 'disabled' | 'primary' | 'secondary' | 'warning'

const Button = React.forwardRef<
  HTMLButtonElement,
  {
    children: ReactNode
    isFullWidth?: boolean
    isAlignedLeading?: boolean
    kind?: KindLike
    onClick?: ReactEventHandler
    onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>
    size?: SizeLike
    disabled?: boolean
    [k: string]: any
  }
>((props, ref) => {
  const {
    children,
    isAlignedLeading = false,
    isFullWidth = false,
    kind = 'primary',
    onClick,
    onKeyDown,
    size = 'm',
    disabled = false,
    ...rest
  } = props

  const getClassNames = (size: SizeLike, kind: KindLike) => {
    let classList = [styles.button]

    switch (size) {
      case 's':
        classList.push(styles.buttonS)
        break
      default:
        classList.push(styles.buttonM)
        break
    }

    if (isFullWidth) classList.push(styles.buttonFullWidth)
    if (isAlignedLeading) classList.push(styles.buttonAlignLeading)

    // Добавляем стиль `disabled` только если disabled === true, и после всех других стилей
    if (disabled) {
      classList.push(styles.buttonDisabled)
    } else {
      switch (kind) {
        case 'danger':
          classList.push(styles.buttonDanger)
          break
        case 'secondary':
          classList.push(styles.buttonSecondary)
          break
        case 'warning':
          classList.push(styles.buttonWarning)
          break
        default:
          classList.push(styles.buttonPrimary)
      }
    }

    return classList.join(' ')
  }

  return (
    <button
      className={getClassNames(size, kind)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      ref={ref as ForwardedRef<HTMLButtonElement>}
      tabIndex={0}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  )
})

export default Button
