import { GoogleIcon } from '../assets'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../store'
import { signInWithGoogle, setCurrentUser, UserLike } from '../store/index'

import { useNavigate } from 'react-router-dom'
import { routes } from '../AppRouts'
import { useEffect } from 'react'
import { getRedirectResult } from 'firebase/auth'
import { auth } from '../services/firebase'

import styles from './SignInbyGoogle.module.css'
import Button from '../ui-kit/Button'

const SignInbyGoogle = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleGoogleSignIn = () => {
    console.log('Попытка авторизации через Google')
    dispatch(signInWithGoogle())
  }

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        console.log('Обработка результата перенаправления:', result)
        if (result && result.user) {
          // Извлечение данных пользователя
          const userLike: UserLike = {
            id: result.user.uid,
            email: result.user.email!,
            name: result.user.displayName!,
            photoURL: result.user.photoURL!,
            createdDate: result.user.metadata.creationTime!,
            lastUpdated: result.user.metadata.lastSignInTime!,
          }

          // Отправка данных пользователя в Redux
          dispatch(setCurrentUser(userLike))

          // Перенаправление на нужную страницу
          navigate(routes.notes)
        }
      })
      .catch((error: Error) => {
        console.log('Ошибка при получении результата перенаправления:', error)
      })
  }, [navigate, dispatch])

  return (
    <div className={styles.wrapper}>
      <Button kind={'secondary'} isFullWidth onClick={handleGoogleSignIn}>
        <GoogleIcon /> Continue with Google
      </Button>
    </div>
  )
}

export default SignInbyGoogle
