import React, { createContext, useContext, useState, useCallback } from 'react'

interface ModuleFunctions {
  openTranslateWord: (word: string) => void
  openTranslateText: (text: string) => void
  openCowriter: () => void
  openParaphrase: () => void
  openGrammar: () => void
  openPlagiarism: () => void
  openTemplates: () => void
  closeTranslateWord: () => void
  closeTranslateText: () => void
  closeCowriter: () => void
  closeParaphrase: () => void
  closeGrammar: () => void
  closePlagiarism: () => void
  closeTemplates: () => void
  closeAllModules: () => void
  isTranslateWordOpen: boolean
  isTranslateTextOpen: boolean
  isCowriterOpen: boolean
  isParaphraseOpen: boolean
  isGrammarOpen: boolean
  isPlagiarismOpen: boolean
  isTemplatesOpen: boolean
  translateWord: string
  translateText: string
  setOnClose: (callback: () => void) => void
}

const ModulesContext = createContext<ModuleFunctions | null>(null)

export const useModulesContext = (): ModuleFunctions => {
  const context = useContext(ModulesContext)
  if (!context) {
    throw new Error('useModulesContext must be used within a ModulesProvider')
  }
  return context
}

interface ModulesProviderProps {
  children: React.ReactNode
}

export const ModulesProvider: React.FC<ModulesProviderProps> = ({
  children,
}) => {
  const [isTranslateWordOpen, setTranslateWordOpen] = useState(false)
  const [isTranslateTextOpen, setTranslateTextOpen] = useState(false)
  const [isCowriterOpen, setCowriterOpen] = useState(false)
  const [isParaphraseOpen, setParaphraseOpen] = useState(false)
  const [isGrammarOpen, setGrammarOpen] = useState(false)
  const [isPlagiarismOpen, setPlagiarismOpen] = useState(false)
  const [isTemplatesOpen, setTemplatesOpen] = useState(false)
  const [translateWord, setTranslateWord] = useState('')
  const [translateText, setTranslateText] = useState('')
  const [onClose, setOnClose] = useState<() => void>(() => {})

  const closeAllModules = useCallback(() => {
    setTranslateWordOpen(false)
    setTranslateTextOpen(false)
    setCowriterOpen(false)
    setParaphraseOpen(false)
    setGrammarOpen(false)
    setPlagiarismOpen(false)
    setTemplatesOpen(false)
    if (onClose) onClose()
  }, [onClose])

  const openTranslateWord = useCallback(
    (word: string) => {
      closeAllModules()
      setTranslateWord(word)
      setTranslateWordOpen(true)
    },
    [closeAllModules]
  )

  const openTranslateText = useCallback(
    (text: string) => {
      closeAllModules()
      setTranslateText(text)
      setTranslateTextOpen(true)
    },
    [closeAllModules]
  )

  const closeTranslateWord = useCallback(() => {
    setTranslateWordOpen(false)
    if (onClose) onClose()
  }, [onClose])

  const closeTranslateText = useCallback(() => {
    setTranslateTextOpen(false)
    if (onClose) onClose()
  }, [onClose])

  const openCowriter = useCallback(() => {
    closeAllModules()
    setCowriterOpen(true)
  }, [closeAllModules])

  const closeCowriter = useCallback(() => {
    setCowriterOpen(false)
    if (onClose) onClose()
  }, [onClose])

  const openParaphrase = useCallback(() => {
    closeAllModules()
    setParaphraseOpen(true)
  }, [closeAllModules])

  const closeParaphrase = useCallback(() => {
    setParaphraseOpen(false)
    if (onClose) onClose()
  }, [onClose])

  const openGrammar = useCallback(() => {
    closeAllModules()
    setGrammarOpen(true)
  }, [closeAllModules])

  const closeGrammar = useCallback(() => {
    setGrammarOpen(false)
    if (onClose) onClose()
  }, [onClose])

  const openPlagiarism = useCallback(() => {
    closeAllModules()
    setPlagiarismOpen(true)
  }, [closeAllModules])

  const closePlagiarism = useCallback(() => {
    setPlagiarismOpen(false)
    if (onClose) onClose()
  }, [onClose])

  const openTemplates = useCallback(() => {
    closeAllModules()
    setTemplatesOpen(true)
  }, [closeAllModules])

  const closeTemplates = useCallback(() => {
    setTemplatesOpen(false)
    if (onClose) onClose()
  }, [onClose])

  return (
    <ModulesContext.Provider
      value={{
        openTranslateWord,
        openTranslateText,
        openCowriter,
        openParaphrase,
        openGrammar,
        openPlagiarism,
        openTemplates,
        closeTranslateWord,
        closeTranslateText,
        closeCowriter,
        closeParaphrase,
        closeGrammar,
        closePlagiarism,
        closeTemplates,
        closeAllModules,
        isTranslateWordOpen,
        isTranslateTextOpen,
        isCowriterOpen,
        isParaphraseOpen,
        isGrammarOpen,
        isPlagiarismOpen,
        isTemplatesOpen,
        translateWord,
        translateText,
        setOnClose,
      }}
    >
      {children}
    </ModulesContext.Provider>
  )
}
