import React from 'react'
import ReactDOM from 'react-dom'
import styles from './Notification.module.css'
import * as Toast from '@radix-ui/react-toast'

interface NotificationProps {
  title: string
  description?: string
  open: boolean
  onOpenChange: (open: boolean) => void
  success?: boolean // Зеленый цвет
  warning?: boolean // Красный цвет
  inform?: boolean // Синий цвет
  onAction?: () => void
  actionName?: string
}

function Notification({
  title,
  description, // Может быть undefined
  open,
  onOpenChange,
  success,
  warning,
  inform,
  onAction,
  actionName,
}: NotificationProps) {
  let notificationClass = styles.ToastRoot

  if (success) notificationClass = styles.ToastRootSuccess
  if (warning) notificationClass = styles.ToastRootWarning
  if (inform) notificationClass = styles.ToastRootInform

  const toastElement = (
    <Toast.Provider swipeDirection="right">
      <Toast.Root
        className={`${styles.ToastRoot} ${notificationClass}`}
        open={open}
        onOpenChange={onOpenChange}
      >
        <Toast.Title className={styles.ToastTitle}>{title}</Toast.Title>
        {description && (
          <Toast.Description asChild>
            <time className={styles.ToastDescription}>{description}</time>
          </Toast.Description>
        )}
        {actionName && onAction && (
          <Toast.Action
            className={styles.ToastAction}
            asChild
            altText="Goto schedule to undo"
          >
            <button className="Button small green" onClick={onAction}>
              {actionName}
            </button>
          </Toast.Action>
        )}
      </Toast.Root>
      <Toast.Viewport className={styles.ToastViewport} />
    </Toast.Provider>
  )

  return ReactDOM.createPortal(
    toastElement,
    document.getElementById('toast-root')!
  )
}

export default Notification
