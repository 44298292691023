import React from 'react'
import styles from './NoteMenu.module.css'
import Divider from '../../ui-kit/Divider'
import DeleteConfirm from './DeleteConfirm'
import IconButton from '../../ui-kit/IconButton'
import * as Toast from '@radix-ui/react-toast'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {
  MenuDotsIcon,
  LeftBackIcon,
  RightBackIcon,
  CutIcon,
  PastIcon,
  SelectIcon,
  SendIcon,
  CopyIcon,
} from '../../assets'

import { HistoryEditor } from 'slate-history'
import { useEditorContext, selectAllText } from '../../contexts/EditorContext'
import { ReactEditor } from 'slate-react'
import { Transforms } from 'slate'
import { Node, Editor } from 'slate'

function NoteMenu() {
  const [success, setSuccess] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [hasOpenDialog, setHasOpenDialog] = React.useState(false)
  const dropdownTriggerRef = React.useRef<HTMLButtonElement>(null)
  const focusRef = React.useRef<HTMLButtonElement | null>(null)

  const handleDialogSelect = (): void => {
    focusRef.current = dropdownTriggerRef.current
  }

  const handleDialogOpenChange = (open: boolean) => {
    setHasOpenDialog(open)
    if (open === false) {
      setDropdownOpen(false)
    }
  }

  const { editor } = useEditorContext()

  const historyEditor = editor as unknown as HistoryEditor

  if (!historyEditor || typeof historyEditor.undo !== 'function') {
    return <div></div>
  }

  const handleSelectAllClick = (event?: React.MouseEvent) => {
    if (event) event.preventDefault()
    if (editor) {
      selectAllText(editor as unknown as ReactEditor) // Приведение типа
    }
  }

  const handleCopy = () => {
    document.execCommand('copy')
  }

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText()
      if (editor && editor.selection) {
        Transforms.insertText(editor, text)
      }
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err)
    }
  }

  const handleCut = () => {
    if (editor && editor.selection) {
      Transforms.delete(editor)
      document.execCommand('cut')
    }
  }

  const serializeWithNewLines = (editor: Editor) => {
    let result = ''
    for (const [node] of Node.nodes(editor)) {
      if ('type' in node) {
        const text = Node.string(node)
        switch (node.type) {
          case 'paragraph':
            result += text + '\n'
            break
          case 'h2':
            result += `## ${text}\n` // Дополнительный перенос строки
            break
          case 'h3':
            result += `### ${text}\n` // Дополнительный перенос строки
            break

          default:
            result += text + '\n'
            break
        }
      }
    }
    return result
  }

  const handleCopyClick = () => {
    if (editor) {
      const allText = serializeWithNewLines(editor)
      navigator.clipboard
        .writeText(allText)
        .then(() => {
          console.log('Text successfully copied to clipboard')
          setSuccess(true) // Успешное копирование
          setOpen(true) // Открыть уведомление
        })
        .catch((err) => {
          console.log('Failed to copy text: ', err)
          setSuccess(false) // Копирование не удалось
          setOpen(true) // Открыть уведомление
        })
    }
  }

  return (
    <Toast.Provider swipeDirection="right">
      <DropdownMenu.Root open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenu.Trigger asChild>
          <span>
            <IconButton>
              <MenuDotsIcon className={styles.icon} />
            </IconButton>
          </span>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className={styles.DropdownMenuContent}
            sideOffset={0}
            side="bottom"
            align="end"
            alignOffset={-4}
            hideWhenDetached={true}
            loop={true}
            hidden={hasOpenDialog}
            onCloseAutoFocus={(event) => {
              if (focusRef.current) {
                focusRef.current.focus()
                focusRef.current = null
                event.preventDefault()
              }
            }}
          >
            <div className={styles.mobileAction}>
              <div className={styles.DropdownMenuContentWrapper}>
                <DropdownMenu.Item
                  className={styles.DropdownMenuItem}
                  onClick={handleCopyClick}
                >
                  <CopyIcon className={styles.icon} />
                  Copy all text <div className={styles.RightSlot}></div>
                </DropdownMenu.Item>
                <DropdownMenu.Item className={styles.DropdownMenuItem}>
                  <SendIcon className={styles.icon} />
                  Send via email <div className={styles.RightSlot}></div>
                </DropdownMenu.Item>
              </div>
              <Divider direction="H" />
            </div>

            <div className={styles.DropdownMenuContentWrapper}>
              <DropdownMenu.Item
                className={styles.DropdownMenuItem}
                onMouseDown={(event) => {
                  event.preventDefault()
                  historyEditor.undo()
                }}
                onKeyDown={(event) => {
                  event.preventDefault()
                  historyEditor.undo()
                }}
              >
                <LeftBackIcon className={styles.icon} />
                Undo <div className={styles.RightSlot}>⌘+Z</div>
              </DropdownMenu.Item>

              <DropdownMenu.Item
                className={styles.DropdownMenuItem}
                onMouseDown={(event) => {
                  event.preventDefault()
                  historyEditor.redo()
                }}
                onKeyDown={(event) => {
                  event.preventDefault()
                  historyEditor.redo()
                }}
              >
                <RightBackIcon className={styles.icon} />
                Redo <div className={styles.RightSlot}>⌘+E</div>
              </DropdownMenu.Item>
            </div>

            <Divider direction="H" />
            <div className={styles.DropdownMenuContentWrapper}>
              <DropdownMenu.Item
                className={styles.DropdownMenuItem}
                onClick={handleCopy}
              >
                <CopyIcon className={styles.icon} />
                Copy<div className={styles.RightSlot}>⌘+C</div>
              </DropdownMenu.Item>

              <DropdownMenu.Item
                className={styles.DropdownMenuItem}
                onClick={handleCut}
              >
                <CutIcon className={styles.icon} />
                Cut<div className={styles.RightSlot}>⌘+X</div>
              </DropdownMenu.Item>

              <DropdownMenu.Item
                className={styles.DropdownMenuItem}
                onClick={handlePaste}
              >
                <PastIcon className={styles.icon} />
                Past<div className={styles.RightSlot}>⌘+V</div>
              </DropdownMenu.Item>

              <DropdownMenu.Item
                className={styles.DropdownMenuItem}
                onClick={() => {
                  handleSelectAllClick()
                  handleDialogOpenChange(false)
                }}
              >
                <SelectIcon className={styles.icon} />
                Select all<div className={styles.RightSlot}>⌘+A</div>
              </DropdownMenu.Item>
            </div>

            <Divider direction="H" />

            <div className={styles.DropdownMenuContentWrapper}>
              <DeleteConfirm
                onSelect={handleDialogSelect}
                onOpenChange={handleDialogOpenChange}
              />
            </div>

            <DropdownMenu.Arrow className={styles.DropdownMenuArrow} />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <Toast.Root
        className={styles.ToastRoot}
        open={open}
        onOpenChange={setOpen}
      >
        <Toast.Title className={styles.ToastTitle}>
          {success ? 'All text successfully copied!' : 'Failed to copy text.'}
        </Toast.Title>
        <Toast.Description>
          {success
            ? 'Use command + V to paste it'
            : 'An error occurred while copying.'}
        </Toast.Description>
        <Toast.Action className={styles.ToastAction} asChild altText="Ok">
          <button className="Button small green" onClick={() => setOpen(false)}>
            Ok
          </button>
        </Toast.Action>
      </Toast.Root>

      <Toast.Viewport className={styles.ToastViewport} />
    </Toast.Provider>
  )
}

export default NoteMenu
