import styles from './LinkTo.module.css'
import { RightArrowIcon } from '../assets/'

const LinkTo = () => {
  return (
    <a
      href="https://diglot.ai"
      // target="_blank"
      rel="noreferrer"
      className={styles.link}
    >
      LEARN MORE ABOUT DIGLOT.AI
      <RightArrowIcon />
    </a>
  )
}

export default LinkTo
