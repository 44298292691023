import React, { forwardRef, useState, useEffect } from 'react'
import {
  updateUserProfile,
  selectCurrentUser,
  AppDispatch,
  UserLike,
} from '../../store'
import { useSelector, useDispatch } from 'react-redux'
import styles from './UserAccount.module.css'
import Button from '../../ui-kit/Button'
import Input from '../../ui-kit/Input'
import Avatar from '../../ui-kit/Avatar'
import Notification from '../../ui-kit/Notification'
import Spinner from '../../ui-kit/Spinner'
import PhotoUpload from './PhotoUpload' // Убедитесь, что этот компонент импортирован

interface UserAccountProps {
  onBackClick: () => void
}

const UserAccount = forwardRef<HTMLButtonElement, UserAccountProps>(
  (props, ref) => {
    const dispatch: AppDispatch = useDispatch()
    const user = useSelector(selectCurrentUser) as UserLike | null
    const [name, setName] = useState(user?.name || '')
    const [initialName, setInitialName] = useState(user?.name || '')
    const [photoURL, setPhotoURL] = useState(user?.photoURL || '')
    const [isLoading, setIsLoading] = useState(false)
    const [isInputChanged, setInputChanged] = useState(false)
    const [showPhotoUpload, setShowPhotoUpload] = useState(false)

    useEffect(() => {
      setInitialName(user?.name || '')
      setName(user?.name || '')
      setPhotoURL(user?.photoURL || '')
    }, [user])

    const handleNameChange = (value: string) => {
      setName(value)
      setInputChanged(value !== initialName)
    }

    const [toast, setToast] = useState<{
      content: string
      status: 'success' | 'error'
    } | null>(null)

    const showToast = (status: 'success' | 'error', message: string) => {
      setToast({ content: message, status })
    }

    const handleUploadComplete = (newPhotoURL: string) => {
      setPhotoURL(newPhotoURL)
      setInputChanged(true)
      setShowPhotoUpload(false)
    }

    const handleCancelUpload = () => {
      setShowPhotoUpload(false)
    }

    const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!isInputChanged) {
        return
      }

      try {
        setIsLoading(true)
        await dispatch(
          updateUserProfile({
            userId: user!.id,
            id: user!.id,
            name,
            photoURL,
            createdDate: user!.createdDate,
            lastUpdated: user!.lastUpdated,
          })
        ).unwrap()

        setIsLoading(false)
        setInitialName(name)
        setInputChanged(false)
        showToast('success', 'Profile successfully updated!')
      } catch (error) {
        setIsLoading(false)
        showToast('error', 'An error occurred while updating the profile.')
      }
    }

    if (isLoading) {
      return (
        <div className={styles.loading}>
          <Spinner />
          <span>Loading...</span>
        </div>
      )
    }

    if (showPhotoUpload) {
      return (
        <PhotoUpload
          onUploadComplete={handleUploadComplete}
          onCancel={handleCancelUpload}
        />
      )
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.titleWraper}>
            <div className={styles.title}>Change Account Settings</div>
            <div className={styles.subtitle}>
              Be in all attention what you may do here!
            </div>
          </div>
          <form className={styles.column} onSubmit={handleSave}>
            <div className={styles.avatarWrapper}>
              <Avatar size="m" photoURL={photoURL} />
              <div className={styles.avatarText}>
                <div className={styles.name}>User Avatar</div>
                <button
                  type="button"
                  className={styles.button}
                  ref={ref}
                  onClick={() => setShowPhotoUpload(true)}
                >
                  Change Picture
                </button>
              </div>
            </div>
            <Input
              autoComplete="given-name"
              isRequired
              label="Name"
              value={name}
              onChange={(value: string) => handleNameChange(value)}
              size="s"
            />
            <div className={styles.buttonWrapper}>
              <Button type="submit" size="s" disabled={!isInputChanged}>
                Save
              </Button>
            </div>
          </form>
        </div>
        <Notification
          title={toast?.content || ''}
          open={toast !== null}
          onOpenChange={() => setToast(null)}
          success={toast?.status === 'success'}
          warning={toast?.status === 'error'}
        />
      </div>
    )
  }
)

export default UserAccount
