import React, { useEffect, useState } from 'react'
import Hotkeys from '../../hotkeys/Hotkeys'
import styles from './HelpMenuItem.module.css'
import Divider from '../../ui-kit/Divider'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {
  RightArrowIcon,
  HelpIcon,
  BookIcon,
  ZapIcon,
  MailIcon,
  BugIcon,
  NewsIcon,
  RoadmapIcon,
  MessageIcon,
  TwitterIcon,
  DiscordIcon,
  YoutubeIcon,
} from '../../assets'

function HelpMenuItem() {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [hasOpenDialog, setHasOpenDialog] = React.useState(false)
  const dropdownTriggerRef = React.useRef<HTMLButtonElement>(null)
  const focusRef = React.useRef<HTMLButtonElement | null>(null)

  const handleHotkeysSelect = (): void => {
    focusRef.current = dropdownTriggerRef.current
  }

  const handleHotkeysOpenChange = (open: boolean): void => {
    setHasOpenDialog(open)
    if (open === false) {
      setDropdownOpen(false)
    }
  }

  const [dropdownMenuPosition, setDropdownMenuPosition] = useState<
    'right' | 'top' | 'bottom' | 'left' | undefined
  >('right')

  useEffect(() => {
    const updateSide = () => {
      if (window.innerWidth < 600) {
        setDropdownMenuPosition('top')
      } else {
        setDropdownMenuPosition('right')
      }
    }

    // Вызываем функцию при первом рендере
    updateSide()

    // Добавляем обработчик события при изменении размера окна
    window.addEventListener('resize', updateSide)

    // Удаляем обработчик события при размонтировании компонента
    return () => {
      window.removeEventListener('resize', updateSide)
    }
  }, [])

  return (
    <DropdownMenu.Root open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenu.Trigger asChild>
        <button className={styles.wrapper} ref={dropdownTriggerRef}>
          <div className={styles.iconText}>
            <HelpIcon className={styles.icon} />
            <div>Help and Community</div>
          </div>
          <RightArrowIcon className={styles.iconRight} />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={styles.DropdownMenuContent}
          sideOffset={16}
          side={dropdownMenuPosition}
          align="end"
          loop={true}
          alignOffset={0}
          hidden={hasOpenDialog}
          onCloseAutoFocus={(event) => {
            if (focusRef.current) {
              focusRef.current.focus()
              focusRef.current = null
              event.preventDefault()
            }
          }}
        >
          <DropdownMenu.Sub>
            <div className={styles.DropdownMenuContentWrapper}>
              <DropdownMenu.SubTrigger
                className={styles.DropdownMenuSubTrigger}
              >
                <MessageIcon className={styles.iconLeft} />
                Community
                <div className={styles.RightSlot}>
                  <RightArrowIcon className={styles.iconRight} />
                </div>
              </DropdownMenu.SubTrigger>
            </div>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent
                className={styles.DropdownMenuSubContent}
                sideOffset={12}
                alignOffset={-40}
                data-side={dropdownMenuPosition}
              >
                <div className={styles.DropdownMenuContentWrapper}>
                  <a
                    href="https://twitter.com/diglot_ai"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.link}
                  >
                    <DropdownMenu.Item className={styles.DropdownMenuItem}>
                      <TwitterIcon className={styles.iconLeft} />
                      Twitter<div className={styles.RightSlot}></div>
                    </DropdownMenu.Item>
                  </a>

                  <DropdownMenu.Item className={styles.DropdownMenuItem}>
                    <YoutubeIcon className={styles.iconLeft} />
                    Youtube
                  </DropdownMenu.Item>

                  <DropdownMenu.Item className={styles.DropdownMenuItem}>
                    <DiscordIcon className={styles.iconLeft} />
                    Discord
                  </DropdownMenu.Item>
                </div>
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>

          <Divider direction="H" />
          <div className={styles.DropdownMenuContentWrapper}>
            <DropdownMenu.Item className={styles.DropdownMenuItem}>
              <ZapIcon className={styles.iconLeft} />
              Interactive Tour <div className={styles.RightSlot}></div>
            </DropdownMenu.Item>

            <DropdownMenu.Item className={styles.DropdownMenuItem}>
              <MailIcon className={styles.iconLeft} />
              Message Support <div className={styles.RightSlot}></div>
            </DropdownMenu.Item>

            <DropdownMenu.Item className={styles.DropdownMenuItem}>
              <BugIcon className={styles.iconLeft} />
              Report a Bug <div className={styles.RightSlot}></div>
            </DropdownMenu.Item>
          </div>

          <Divider direction="H" />
          <div className={styles.DropdownMenuContentWrapper}>
            <DropdownMenu.Item className={styles.DropdownMenuItem}>
              <NewsIcon className={styles.iconLeft} />
              What’s New? <div className={styles.RightSlot}></div>
            </DropdownMenu.Item>

            <DropdownMenu.Item className={styles.DropdownMenuItem}>
              <RoadmapIcon className={styles.iconLeft} />
              Features & Roadmap <div className={styles.RightSlot}></div>
            </DropdownMenu.Item>
          </div>

          <Divider direction="H" />
          <div className={styles.DropdownMenuContentWrapper}>
            <DropdownMenu.Item className={styles.DropdownMenuItem}>
              <BookIcon className={styles.iconLeft} />
              Help & Tutorials<div className={styles.RightSlot}></div>
            </DropdownMenu.Item>
            <Hotkeys
              onSelect={handleHotkeysSelect}
              onOpenChange={handleHotkeysOpenChange}
            />
            {/* <DropdownMenu.Item className={styles.DropdownMenuItem}>
              <CommandIcon className={styles.iconLeft} />
              Hotkeys <div className={styles.RightSlot}>⇧+⌘+H</div>
            </DropdownMenu.Item> */}
          </div>

          <DropdownMenu.Arrow className={styles.DropdownMenuArrow} />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default HelpMenuItem
