import React from 'react'
import classNames from 'classnames'
import styles from './MenuItem.module.css'
import {
  CowriterIcon,
  TranslateIcon,
  GrammarIcon,
  ParaphraseIcon,
  PlagiarismIcon,
  TemplateIcon,
} from '../assets'

interface MenuItemProps {
  active: boolean
  type: 'cowri' | 'trans' | 'gramm' | 'para' | 'plag' | 'templ'
  onClick: () => void
}

const icons = {
  cowri: CowriterIcon,
  trans: TranslateIcon,
  gramm: GrammarIcon,
  para: ParaphraseIcon,
  plag: PlagiarismIcon,
  templ: TemplateIcon,
}

const MenuItem: React.FC<MenuItemProps> = ({ active, type, onClick }) => {
  const IconComponent = icons[type]

  return (
    <button
      className={classNames(
        styles.menuItem,
        { [styles.active]: active },
        styles[type]
      )}
      onClick={onClick}
    >
      <IconComponent className={styles.icon} />
    </button>
  )
}

export default MenuItem
