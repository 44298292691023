import React, { useState, useRef } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import style from './PhotoUpload.module.css'
import Button from '../../ui-kit/Button'
import Spinner from '../../ui-kit/Spinner'
import Notification from '../../ui-kit/Notification'

import { selectCurrentUser, UserLike } from '../../store'
import { useSelector } from 'react-redux'
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage' // Импорт Firebase SDK

interface Toast {
  content: string
  status: 'success' | 'error'
}

interface PhotoUploadProps {
  onUploadComplete: (photoURL: string) => void
  onCancel: () => void
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({
  onUploadComplete,
  onCancel,
}) => {
  const user = useSelector(selectCurrentUser) as UserLike | null

  const [isPhoto, setIsPhoto] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [previewURL, setPreviewURL] = useState<string | null>(null)
  const cropperRef = useRef<any>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [toast, setToast] = useState<Toast | null>(null)

  const MAX_FILE_SIZE_MB = 2
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024
  const MIN_IMAGE_DIMENSION = 64

  const isValidImageSize = async (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const image = new Image()
      const reader = new FileReader()
      reader.onloadend = () => {
        image.src = reader.result as string
        image.onload = () => {
          resolve(
            image.width >= MIN_IMAGE_DIMENSION &&
              image.height >= MIN_IMAGE_DIMENSION
          )
        }
      }
      reader.readAsDataURL(file)
    })
  }

  const updateFile = async (file: File) => {
    if (file.size > MAX_FILE_SIZE_BYTES) {
      setToast({ content: 'File size must be less than 2 MB', status: 'error' })
      return
    }

    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif']
    if (!validImageTypes.includes(file.type)) {
      setToast({ content: 'Only raster images are allowed', status: 'error' })
      return
    }

    const validSize = await isValidImageSize(file)
    if (!validSize) {
      setToast({
        content: 'Image dimensions should be at least 64x64',
        status: 'error',
      })
      return
    }

    // setSelectedFile(file)
    setIsPhoto(true)

    const reader = new FileReader()
    reader.onloadend = () => {
      setPreviewURL(reader.result as string)
    }
    reader.readAsDataURL(file)
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      updateFile(file)
    }
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (file) {
      updateFile(file)
    }
  }

  const handleSave = async () => {
    setIsLoading(true) // Включаем спиннер
    const cropper = cropperRef.current?.cropper
    if (cropper) {
      cropper.getCroppedCanvas().toBlob(async (blob: Blob) => {
        if (blob && user?.id) {
          const storage = getStorage()
          const fileName = `cropped_${new Date().getTime()}.jpg`
          const fileRef = storageRef(storage, `${user.id}/${fileName}`)

          await uploadBytes(fileRef, blob)
          const fileURL = await getDownloadURL(fileRef)
          setIsLoading(false) // Выключаем спиннер
          onUploadComplete(fileURL)
        }
      })
    }
  }

  if (isLoading) {
    return (
      <div className={style.loading}>
        <Spinner />
        <span>Uploading...</span>
      </div>
    )
  }

  return (
    <div className={style.wrapper}>
      <div
        className={style.dropZone}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {previewURL ? (
          <Cropper
            src={previewURL}
            aspectRatio={1}
            style={{ height: 400, width: '100%' }}
            ref={cropperRef}
          />
        ) : (
          <div className={style.textWrapper}>
            <div className={style.title}>
              Drag and Drop or Click Select File below.
            </div>
            <div className={style.textInfo}>
              Only raster image files with resolution from 64x64 to 1024x1024
              pixels are allowed.
            </div>
          </div>
        )}
      </div>
      <div className={style.buttonWrapper}>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileSelect}
        />
        <Button
          size="s"
          onClick={() => {
            fileInputRef.current?.click()
          }}
        >
          Select File
        </Button>
        <div className={style.buttonRight}>
          <Button size="s" kind="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="s" disabled={!isPhoto} onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
      <Notification
        title={toast?.content || ''}
        open={toast !== null}
        onOpenChange={() => setToast(null)}
        warning={toast?.status === 'error'}
      />
    </div>
  )
}

export default PhotoUpload
