import styles from './Divider.module.css'

interface DividerProps {
  direction: 'V' | 'H'
}

const Divider: React.FC<DividerProps> = ({ direction }) => {
  const className = `${direction === 'V' ? styles.vertical : styles.horizontal}`

  return <div className={className} />
}

export default Divider
