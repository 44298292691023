import React from 'react'
import * as AlertDialog from '@radix-ui/react-alert-dialog'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styles from './DeleteConfirm.module.css'
import { TrashIcon } from '../../assets'
import Button from '../../ui-kit/Button'

import { useSelector } from 'react-redux'

import {
  appDispatch,
  deleteNoteAndSetCurrent,
  selectCurrentNote,
  selectIsSaving,
} from '../../store'

interface DeleteConfirmProps {
  onSelect: () => void
  onOpenChange: (open: boolean) => void
}

const DeleteConfirm: React.FC<DeleteConfirmProps> = ({
  onSelect,
  onOpenChange,
}) => {
  const isNoteSaving = useSelector(selectIsSaving)
  const note = useSelector(selectCurrentNote)

  return (
    <AlertDialog.Root onOpenChange={onOpenChange}>
      <AlertDialog.Trigger asChild>
        <DropdownMenu.Item
          className={styles.item}
          onSelect={(event) => {
            event.preventDefault()
            onSelect()
          }}
        >
          <TrashIcon className={styles.icon} />
          Move to Trash <div className={styles.RightSlot}>⌘+D</div>
        </DropdownMenu.Item>
      </AlertDialog.Trigger>

      <AlertDialog.Portal>
        <AlertDialog.Overlay className={styles.AlertDialogOverlay} />
        <AlertDialog.Content className={styles.AlertDialogContent}>
          <AlertDialog.Title className={styles.AlertDialogTitle}>
            Are you absolutely sure?
          </AlertDialog.Title>
          <AlertDialog.Description className={styles.AlertDialogDescription}>
            By the way you can restore your note from the Trash in any time.
          </AlertDialog.Description>
          <div style={{ display: 'flex', gap: 16, justifyContent: 'flex-end' }}>
            <AlertDialog.Cancel asChild>
              <Button size="s" kind="secondary">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <Button
                size="s"
                kind={isNoteSaving ? 'disabled' : 'warning'}
                onClick={() => {
                  note && appDispatch(deleteNoteAndSetCurrent(note.id))
                }}
              >
                Yes, delete note
              </Button>
            </AlertDialog.Action>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}

export default DeleteConfirm
