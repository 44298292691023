import React, { forwardRef } from 'react'
import styles from './Export.module.css'
// import IconButton from '../../ui-kit/IconButton'
import Button from '../../ui-kit/Button'
import Divider from '../../ui-kit/Divider'
import * as RadioGroup from '@radix-ui/react-radio-group'

// import { LeftArrowIcon } from '../../assets'

interface ExportProps {
  onBackClick: () => void
}

const Export = forwardRef<HTMLDivElement, ExportProps>((props, ref) => {
  // const { onBackClick } = props

  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.top}>
        <IconButton secondary onClick={onBackClick}>
          <LeftArrowIcon />
        </IconButton>
      </div> */}
      <div className={styles.content}>
        <div className={styles.titleWraper}>
          <div className={styles.title}>Choose export options</div>
          <div className={styles.subtitle}>
            Need to have a record of all your notes? You can download it as a
            TXT or a JSON file.
          </div>
        </div>
        <div className={styles.column}>
          <form className={styles.form}>
            <RadioGroup.Root
              className={styles.RadioGroupRoot}
              defaultValue="default"
              aria-label="View density"
              ref={ref}
            >
              <Divider direction="H" />
              <label className={styles.Label} htmlFor="r1">
                <div className={styles.radioItemWrapper}>
                  <RadioGroup.Item
                    className={styles.RadioGroupItem}
                    value="default"
                    id="r1"
                  >
                    <RadioGroup.Indicator
                      className={styles.RadioGroupIndicator}
                    />
                  </RadioGroup.Item>
                  <div className={styles.titleRadio}>JSON</div>
                </div>
                <div className={styles.description}>
                  Some text Some textSome textSome textSome textSome textSome
                  text
                </div>
              </label>
              <Divider direction="H" />
              <label className={styles.Label} htmlFor="r2">
                <div className={styles.radioItemWrapper}>
                  <RadioGroup.Item
                    className={styles.RadioGroupItem}
                    value="comfortable"
                    id="r2"
                  >
                    <RadioGroup.Indicator
                      className={styles.RadioGroupIndicator}
                    />
                  </RadioGroup.Item>
                  <div className={styles.titleRadio}>TXT</div>
                </div>

                <div className={styles.description}>
                  Some text Some textSome textSome textSome textSome textSome
                  text
                </div>
              </label>
              <Divider direction="H" />
            </RadioGroup.Root>
          </form>

          <div className={styles.buttonWrapper}>
            <Button size="s" kind="primary">
              Export
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Export
