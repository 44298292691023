import React from 'react'
import styles from './EditorLayout.module.css'
import { useEditorContext } from '../../contexts/EditorContext'
import { useModulesContext } from '../../contexts/ModulesContext'
import SidebarMenu from './SidebarMenu'

interface EditorLayoutProps {
  children: React.ReactNode
}

const EditorLayout: React.FC<EditorLayoutProps> = ({ children }) => {
  const { isItFirstTime } = useEditorContext()
  const {
    isTranslateWordOpen,
    isTranslateTextOpen,
    isCowriterOpen,
    isGrammarOpen,
    isPlagiarismOpen,
    isParaphraseOpen,
    isTemplatesOpen,
  } = useModulesContext()

  const isAnyModuleOpen =
    isTranslateWordOpen ||
    isTranslateTextOpen ||
    isCowriterOpen ||
    isGrammarOpen ||
    isPlagiarismOpen ||
    isParaphraseOpen ||
    isTemplatesOpen

  return (
    <div className={styles.wrapper}>
      <div
        className={
          isAnyModuleOpen ? styles.contentNoScroll : styles.contentScroll
        }
      >
        <div
          className={
            !isItFirstTime
              ? styles.contentWrapperWide
              : styles.contentWrapperSlim
          }
        >
          {children}
        </div>
      </div>
      <SidebarMenu />
    </div>
  )
}

export default EditorLayout
