import { Editor, Transforms, Element as SlateElement } from 'slate';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export interface CustomElement extends SlateElement {
  align?: string
  type?: string
}

export const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  if (isActive && isList) {
    // Добавьте этот код для развертывания родительского списка, если он активен
    Transforms.unwrapNodes(editor, {
      match: (n: any) => LIST_TYPES.includes(n.type),
      split: true,
    });
  } else {
    // Ваш существующий код для обработки невыбранных элементов
    Transforms.unwrapNodes(editor, {
      match: (n: any) => {
        const isCustomElement = (node: any): node is CustomElement => {
          return 'type' in node && node.type !== undefined && 'align' in node;
        };
  
        return (
          !Editor.isEditor(n) &&
          isCustomElement(n) &&
          n.type !== undefined &&
          LIST_TYPES.includes(n.type) &&
          !TEXT_ALIGN_TYPES.includes(format)
        );
      },
      split: true,
    });
  }

  let newProperties: Partial<CustomElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }

  Transforms.setNodes<CustomElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};


export const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isBlockActive = (
  editor: Editor,
  format: string,
  blockType: keyof CustomElement = 'type'
) => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: any): n is CustomElement => {
        return (
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (n as CustomElement)[blockType] === format
        )
      },
    })
  )

  return !!match
}

export const isMarkActive = (editor: Editor, format: string): boolean => {
  const marks = Editor.marks(editor)
  return marks
    ? (marks as { [key: string]: boolean | undefined })[format] === true
    : false
}


export const removeFormatting = (editor: Editor) => {
  const { selection } = editor;

  // Если выделение отсутствует, ничего не делать
  if (!selection) return;

  // Сохранить текущее выделение
  const savedSelection = JSON.parse(JSON.stringify(selection));

  Editor.withoutNormalizing(editor, () => {
    // Удалить inline стили
    const marks = ['bold', 'italic', 'underline', 'code'];
    for (const mark of marks) {
      Editor.removeMark(editor, mark);
    }

    // Сбросить тип и выравнивание всех оставшихся блоков на "paragraph"
    Transforms.setNodes<CustomElement>(
      editor,
      {
        type: 'paragraph',
        align: undefined,
      },
      {
        match: (n) => {
          return !Editor.isEditor(n) && SlateElement.isElement(n) && Editor.isBlock(editor, n);
        }
      }
    );

    if (
      Editor.hasPath(editor, savedSelection.anchor.path) &&
      Editor.hasPath(editor, savedSelection.focus.path)
    ) {
      Transforms.unwrapNodes(editor, {
        match: (n: any) => LIST_TYPES.includes(n.type),
        split: true,
      });
    }
  });

  // Восстановить сохраненное выделение, если оно все еще существует
  if (
    Editor.hasPath(editor, savedSelection.anchor.path) &&
    Editor.hasPath(editor, savedSelection.focus.path)
  ) {
    Transforms.select(editor, savedSelection);
  } else {
    // Если пути больше не существуют, сбрасываем выделение
    editor.selection = null;
    
    // Обновляем модель данных, чтобы Slate и DOM синхронизировались
    Editor.normalize(editor, { force: true });
  }
};



